import React, { useEffect, useState } from "react";
import { useParams,useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const [redirectStatus, setRedirectStatus] = useState('');
  const [orderData, setOrderData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Get the current URL only once when the component mounts
    const currentUrl = window.location.href;
    
    // Parse the URL parameters
    const urlParams = new URLSearchParams(currentUrl);
    
    // Get the value of the redirect_status parameter
    const status = urlParams.get("redirect_status");
    const paystatus = status === 'succeeded' ? true : false;
    // Get and decode the orderData parameter
    const encodedOrderData = urlParams.get("orderData");
    const decodedOrderData = encodedOrderData ? JSON.parse(decodeURIComponent(encodedOrderData)) : null;
    setOrderData(decodedOrderData);

    const payment_intent = urlParams.get("payment_intent");
    const payment_intent_client_secret = urlParams.get("payment_intent_client_secret");
    fetch('http://68.178.169.226:12080/api/v1/order/editstatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
            orderid:localStorage.getItem('selectedOrder'),
            paymentdetails: window.location.href,
            paymentdone:paystatus,
            paymentmethod:'card',
          
        }),
      }).then(response => {
        if (response.ok) {
            // Redirect to the ViewOrder page
            if(paystatus){
              setTimeout(() => {
                navigate('/ViewOrder/'+localStorage.getItem('selectedOrder'));
            }, 5000);
          }else{
                setTimeout(() => {
                  navigate('/HomePage');
                }, 5000);
                
                }
              
        } else {
            console.error('API call was not successful:', response.error);
            // Handle the case where the API call was not successful
        }
    })
    
        .catch(error => {
          console.error('Error making API call:', error);
        });
    // Update the state with the status
    setRedirectStatus(status);
  }, []); // Empty dependency array ensures this effect runs once after the initial render

  const { payment_intent, payment_intent_client_secret } = useParams();
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <h1>Payment Status</h1>
      <h3>{redirectStatus === 'succeeded' ? 'Payment Sucess' : 'Apologies for any inconvenience. Your order was canceled due to a payment failure. Please proceed again with checkout.'}</h3>
      <h5>please wait to redirect ....</h5>
      {/* You can use payment_intent and payment_intent_client_secret as needed */}
    </div>
  );
};

export default PaymentSuccess;
