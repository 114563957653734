import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../header/headershop";
import { Helmet } from "react-helmet";
import noimage from "../../src/images/noimage.jpg";
import "../../src/menuCategory/category.css";
import { useDispatch, useSelector } from "react-redux";
import { addProduct, addCart, editCart } from "../Redux/Redux";
import ProductWrap from "../../src/product/productWrap";

const CustomProductsPage = ({ pageName }) => {
  const [itemsForSelectedSubcategory, setItemsForSelectedSubcategory] = useState([]);
  const [currentPages, setCurrentPages] = useState("");

  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.wishlist.value);
  const cart = useSelector((state) => state.cart.value);

  function storeProductID(productid) {
    localStorage.setItem("productid", productid);
  }

  const addToCart = async (productId) => {
    dispatch(addCart(productId));
  };

  const addToWishList = (productid) => {
    dispatch(addProduct(productid));
  };
  useEffect(() => {
    // For Best Offers
    if (pageName === 'BestSellers') {
      fetchBestImages();
    }
    else if (pageName === 'FeaturedProduct') {
      fetchFeaturedImages();
    }else if (pageName === 'HourlyOffers'){
      fetchOfferImages();
    }
  }, []);
  
      const fetchBestImages = async () => {
        try {
          
    const payload = {
      currency: localStorage.getItem('currencyName'),
      page:0,
    };
          const response = await axios.put("http://68.178.169.226:12080/api/v1/product/getbestsellers",  payload,{
      
          headers: {
            // 'Authorization': 'Bearer ' +token,
            'Content-Type': 'application/json',
            },
          });
  
          if (response.status === 200) {
            const  data = response.data.data;
            setItemsForSelectedSubcategory(data);
            const currentPages = response.data.Currentpageno + 1;
            setCurrentPages(currentPages)
          } else {
            console.error('Failed to fetch best seller images');
          }
        } catch (error) {
          console.error('Error fetching best seller images:', error);
        }
      };
  
  
    // FeaturedProduct
      const fetchFeaturedImages = async () => {
        try {
          const payload = {
            currency: localStorage.getItem('currencyName')
          };
          const response = await axios.put("http://68.178.169.226:12080/api/v1/product/getfeaturedproduct",  payload,{
      
          headers: {
            // 'Authorization': 'Bearer ' +token,
            'Content-Type': 'application/json',
            },
          });
  
          if (response.status === 200) {
            const data = response.data;
            setItemsForSelectedSubcategory(data);
          } else {
            console.error('Failed to fetch featured product images');
          }
        } catch (error) {
          console.error('Error fetching featured product images:', error);
        }
      };
  
    // HourlyOffers
      const calculateRemaining = (offersList) => {
        // The same as your existing code
        // ...
        return offersList;
      };
  
      const fetchOfferImages = async () => {
        try {
          const payload = {
            currency: localStorage.getItem('currencyName')
          };
          const response = await axios.put("http://68.178.169.226:12080/api/v1/product/getoffers",  payload,{
      
          headers: {
            // 'Authorization': 'Bearer ' +token,
            'Content-Type': 'application/json',
            },
          });
  
          if (response.status === 200) {
            const data = response.data;
            setItemsForSelectedSubcategory(calculateRemaining(data));
          } else {
            console.error('Failed to fetch hourly offer images');
          }
        } catch (error) {
          console.error('Error fetching hourly offer images:', error);
        }
      };
  

  const updateProductQuantity = async (productId, operation) => {
    let cartItem = cart.find((cartItem) => cartItem.productid == productId);
    if (operation == "add") {
      dispatch(
        editCart({
          productId,
          cartid: cartItem.cartid,
          newQuantity: parseInt(cartItem.productquantity) + 1,
        })
      );
    } else {
      dispatch(
        editCart({
          productId,
          cartid: cartItem.cartid,
          newQuantity: parseInt(cartItem.productquantity) - 1,
        })
      );
    }
  };

  return (
    <div>
      <div>
        <Helmet>
          <title>{pageName}</title>
        </Helmet>
      </div>
      <Ecomheader />
      <div className="container homebg">
            <div
              className={
                itemsForSelectedSubcategory.length === 0
                  ? "row"
                  : "row cols-2 cols-sm-4 cols-lg-5 product-wrapper"
              }
              style={{
                textAlign:
                  itemsForSelectedSubcategory.length === 0 ? "center" : "",
              }}
            >
              {!itemsForSelectedSubcategory ? (
                <h1 className="notavailable">
                  No products available for this category.
                </h1>
              ) : (
                itemsForSelectedSubcategory.map((item) => (
                  <ProductWrap key={item.productid} product={item} />
                ))
              )}
            </div>
            {/* <div className="pagination">
              <p>{currentPages}</p>
            </div> */}
        
      </div>
      <Ecomfooter />
    </div>
  );
};

export default CustomProductsPage;
