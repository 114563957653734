import React, { useState, useEffect } from 'react';
import { useParams,Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Ecomfooter from '../footer/footershop';
import Ecomheader from '../../src/header/headershop';
import { Helmet } from "react-helmet";
import noimage from '../../src/images/noimage.jpg';
import {useDispatch, useSelector} from 'react-redux'
import { addCart, addProduct, editCart } from "../Redux/Redux";
import ProductWrap from "../../src/product/productWrap";      
import NavHead from "../navbarHead";
import MobileMenu from "../../src/navbarHead/mobileNav";
import "../../src/menuCategory/pahination.css";
import SideBar from '../SideBar';

const MenuSub = ({ includeHeaderAndFooter = true }) => {
  const [subcategoryDetails, setSubcategoryDetails] = useState({});
  const [itemsForSelectedSubcategory, setItemsForSelectedSubcategory] = useState([]);
  const [showCategory, setShowCategory] = useState(true);
  const [selectedSubcategoryDetails, setSelectedSubcategoryDetails] = useState(null);
  const { subcategoryid } = useParams();
  // const [cart, setCart] = useState([]);
  const [quantities, setQuantities] = useState([]);
  const [ helmetDetails,sethelmetDetails] = useState(null);
  const [hasItemsInWishlist, setHasItemsInWishlist] = useState(false);
  const navigate = useNavigate();
  const [errorvalue, setErrorvalue] = useState(null);
  const [errorvalueforimg, setErrorvalueforimg] = useState(null);
  const [catagory, setCatagory] = useState({});

  // const [wishlist, setWishList] = useState([]);
  const dispatch = useDispatch();
  const wishlist = useSelector(state => state.wishlist.value)
  const cart = useSelector(state => state.cart.value)
  const [loading,setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1); 
  const [pageNumbers, setPageNumbers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
  const storedQuantities = JSON.parse(localStorage.getItem('quantities')) || [];
  const storedWishList = JSON.parse(localStorage.getItem('wishlist')) || [];

  // setCart(storedCart);
  setQuantities(storedQuantities);
  headerCategoryList();
  // setWishList(storedWishList)
  }, []); // Empty dependency array ensures the effect runs only once
  useEffect(() => {
    headerCategoryList();
  }, [subcategoryid]);
  
  const headerCategoryList = async () => {
    try {
        const response = await axios.put("http://68.178.169.226:12080/api/v1/product/getcategoryAndsubcategory",{
            
        headers: {
            // 'Authorization': 'Bearer ' +token,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          currency: localStorage.getItem('currencyName'),
        }),
        });
            const masubsub=response.data;
            setCatagory(masubsub);
            const cat = masubsub.find(item => item.subcategory.some((sub) => sub.subcategoryid == subcategoryid));
            const sub = cat.subcategory.find(item =>   item.subcategoryid == subcategoryid);
            sethelmetDetails(sub.sortdescription+"-"+sub.fulldescription)
        
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};



  function storeProductID(productid) {
    localStorage.setItem('productid', productid);
  }

  const addToCart = async (productId) => {
    dispatch(addCart(productId))
    // try {
    //   if(!localStorage.getItem("token")){
    //     navigate('/LoginPage')
    //   }
    //   const response = await fetch(
    //     "http://68.178.169.226:12080/api/v1/cart/add",
    //     {
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       },
    //       body: JSON.stringify({
    //         // clientid: cartid,
    //         clientid:localStorage.getItem("clientid"),
    //         productid: productId,
    //         productquantity: 1,
    //       }),
    //     }
    //   );

    //   if (response.ok) {
    //     addCart(productId);
    //   }
    // } catch (error) {
    //   console.error("Error checking authentication:", error);
    // }
  };

  // const addCart = (productid) => {
  //   // Check if the productid is already in the cart
  //   const itemIndex = cart.findIndex((cartItem) => cartItem.productid === productid);

  //   if (itemIndex !== -1) {
  //     // Product is already in the cart, increase the quantity
  //     const updatedCart = [...cart];
  //     updatedCart[itemIndex].quantity += 1;

  //     setCart(updatedCart);
  //     localStorage.setItem('cart', JSON.stringify(updatedCart));
  //   } else {
  //     // Product is not in the cart, add it as a new entry
  //     const updatedCart = [...cart, { productid, quantity: 1 }];
  //     setCart(updatedCart);
  //     localStorage.setItem('cart', JSON.stringify(updatedCart));
  //   }
  // };
  const addToWishList = (productid) => {
    dispatch(addProduct(productid))
    // const itemIndex1 = wishlist.findIndex((wishlistItem) => wishlistItem.productid === productid);
    // if(itemIndex1 !== -1){
    //   const updatedWishList = [...wishlist];
    //   updatedWishList[itemIndex1].quantity += 1;

    //   setWishList(updatedWishList);
    //   localStorage.setItem('wishlist', JSON.stringify(updatedWishList));
    // }
    // else{
    //   const updatedWishList =[...wishlist,{productid}];
    //   setWishList(updatedWishList);
    //   localStorage.setItem('wishlist', JSON.stringify(updatedWishList));
    // }
    setHasItemsInWishlist(true);
  };
  useEffect(() => {
    fetchItemsForSelectedSubcategory(); // Call the function to make the API request
  }, [subcategoryid]); // Empty dependency array means this effect runs once, when the component mounts
    const fetchItemsForSelectedSubcategory = async () => {
      try {
        setLoading(true);
        const response = await axios.put(
          'http://68.178.169.226:12080/api/v1/product/getbySubCategory',
          {
            subcategoryid: subcategoryid,
            currency: localStorage.getItem('currencyName'),
            page:0
          }
        );

        if (response.status === 200) {
          // Handle the API response data as needed
          const items = response.data; // Assuming the response contains the items for the subcategory
          if (Array.isArray(items)) {
            setItemsForSelectedSubcategory(items);
            setSelectedSubcategoryDetails(items);
          }
        } else {
          console.error('API request failed');
        }if(response.data.status === "Error"){
          setItemsForSelectedSubcategory([]);
          setErrorvalue(response.data.description);
          setErrorvalueforimg(response.data.organiserId);
        }
      } catch (error) {
        console.error('Error making API request:', error);
      }
      finally {
          setLoading(false);
      }
      setShowCategory(false);
    };

    const fetchPageNumbers = async () => {
      try {
        const formData = new FormData();
        formData.append('field', 'subcategory');
        formData.append('id',subcategoryid)
        const response = await axios.put(
          'http://68.178.169.226:12080/api/v1/product/page',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data', 
            },
          }
        );
    
        if (response.status === 401 || response.status === 404) {
          navigate('/LoginPage');
          return;
        }
        const totalPagesFromResponse = Math.ceil(parseFloat(response.data)); 
        console.log('totalPagesFromResponse',totalPagesFromResponse);
  
        setTotalPages(totalPagesFromResponse);
        if(totalPagesFromResponse <= 1){
          setPageNumbers([]);
        }else{
        const numbersArray = Array.from({ length: totalPagesFromResponse }, (_, index) => index + 1);
        setPageNumbers(numbersArray);
        }
      } catch (error) {
        console.log('Error fetching page numbers:', error);
      }
    };
    useEffect(() => {
      fetchPageNumbers();
    }, [subcategoryid]);
    useEffect(() => {
      fetchItemsForSelectedSubcategory();
      fetchItemsForPage(0); // Fetch items for the initial page (page 1)
    }, []);
    const handlePageClick = (pageNumber) => {
      setCurrentPage(pageNumber);
      fetchItemsForPage(pageNumber);
    };
    const fetchItemsForPage  = async (pageNumber) => {
      try {
        setLoading(true);
        const response = await axios.put(
          "http://68.178.169.226:12080/api/v1/product/getbySubCategory",
          {
            subcategoryid: subcategoryid,
            currency: localStorage.getItem('currencyName'),
            page:pageNumber,
          }
        );
  
        if (response.status === 200) {
          const items = response.data;
          if (Array.isArray(items)) {
            setItemsForSelectedSubcategory(items);
            const cat = selectedSubcategoryDetails.find((item) => item.subcategoryid == subcategoryid);
            sethelmetDetails(cat.sortdescription + "-" + cat.fulldescription);
  
          }
        } else {
          console.error("API request failed");
        }
        if(response.data.status === "Error"){
          setItemsForSelectedSubcategory([]);
          setErrorvalue(response.data.description);
          setErrorvalueforimg(response.data.organiserId);
        }
      } catch (error) {
        console.error("Error making API request:", error);
      }
      finally {
          setLoading(false);
      }
    };
  const updateProductQuantity = async (productId, operation) => {
    let cartItem = cart.find((cartItem) => cartItem.productid == productId)
    if (operation == 'add') {
      dispatch(editCart({ productId, cartid: cartItem.cartid, newQuantity:parseInt(cartItem.productquantity) + 1 }))
    }
    else {
      dispatch(editCart({ productId, cartid: cartItem.cartid, newQuantity:parseInt(cartItem.productquantity) - 1 }))
    }
  };
  const renderPageNumbers = () => {
    const maxVisiblePages = 4;
    const delta = Math.ceil(maxVisiblePages / 2);
  
    if (totalPages <= maxVisiblePages) {
      return pageNumbers.map((number) => (
        <li
          key={number}
          className={currentPage === number - 1 ? "selected-page" : ""}
          onClick={() => handlePageClick(number - 1)}
        >
          {number}
        </li>
      ));
    }
  
    if (currentPage <= delta) {
      return (
        <>
          {pageNumbers.slice(0, maxVisiblePages - 1).map((number) => (
            <li
              key={number}
              className={currentPage === number - 1 ? "selected-page" : ""}
              onClick={() => handlePageClick(number - 1)}
            >
              {number}
            </li>
          ))}
          <li>...</li>
          <li onClick={() => handlePageClick(totalPages - 1)}
          >
            {totalPages}
          </li>
        </>
      );
    }
  
    if (currentPage >= totalPages - delta) {
      return (
        <>
          <li
            style={{ marginRight: '10px', cursor: 'pointer' }}
            onClick={() => handlePageClick(0)}
          >
            1
          </li>
          <li>...</li>
          {pageNumbers.slice(totalPages - maxVisiblePages + 1).map((number) => (
            <li
              key={number}
              className={currentPage === number - 1 ? "selected-page" : ""}
              style={{ marginRight: '10px', cursor: 'pointer' }}
              onClick={() => handlePageClick(number - 1)}
            >
              {number}
            </li>
          ))}
        </>
      );
    }
    return (
      <>
        <li
          style={{ marginRight: '10px', cursor: 'pointer' }}
          onClick={() => handlePageClick(0)}
        >
          1
        </li>
        <li>...</li>
        {pageNumbers.slice(currentPage - delta, currentPage + delta).map((number) => (
          <li
            key={number}
            className={currentPage === number - 1 ? "selected-page" : ""}
            style={{ marginRight: '10px', cursor: 'pointer' }}
            onClick={() => handlePageClick(number - 1)}
          >
            {number}
          </li>
        ))}
        <li>...</li>
        <li
          style={{ marginRight: '10px', cursor: 'pointer' }}
          onClick={() => handlePageClick(totalPages - 1)}
        >
          {totalPages}
        </li>
      </>
    );
  };
  

  const handlePrevPage = () => {
    if (currentPage > 0) {
      handlePageClick(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      handlePageClick(currentPage + 1);
    }
  };

  return (
    <div>
    <div>
        <Helmet>
        <title>{helmetDetails}</title>
        </Helmet>
    </div>
       {includeHeaderAndFooter && (
    <Ecomheader />
    )}
    <MobileMenu/>
    <div class="container homebg " style={{marginTop:'30px'}}>
      {/* <SideBar categories={catagory} /> */}
        {loading || !itemsForSelectedSubcategory ? (
            <div  className="container-fluied"></div>
        ) : (
          <div className='row d-flex justify-content-center align-item-center'>
              {!itemsForSelectedSubcategory.length ==0 ? (
                itemsForSelectedSubcategory.map((item) => (
                  <ProductWrap key={item.productid} product={item} /> 
                ))
              ) : (
                <h1 className="notavailable"
                style={{
                  display:
                  !itemsForSelectedSubcategory ? 'none':'block'
                }}
                
                >
                <img src={errorvalueforimg}/>
                </h1>
              )}
            </div>
           )}
           {loading || !itemsForSelectedSubcategory     ? (
  <div className="container-fluied">
    <div className="row">
      <div className="muti-col-loader"></div>
    </div>
  </div>
) : (
  <>
  {totalPages > 1 && (
              <ul className="d-flex justify-content-center pagination">
                <li style={{ marginRight: '10px', cursor: 'pointer' }} onClick={handlePrevPage}>
                  {'<'}
                </li>
                {renderPageNumbers()}
                <li style={{ marginRight: '10px', cursor: 'pointer' }} onClick={handleNextPage}>
                  {'>'}
                </li>
              </ul>
  )}
            </>
)}
    </div>
    {includeHeaderAndFooter && (
    <Ecomfooter />
    )}
    {/* </div> */}
 
  </div>
  );
};

export default MenuSub;
