import React, { useState, useEffect,lazy,Suspense } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../header/headershop";
import { Helmet } from "react-helmet";
import noimage from "../../src/images/noimage.jpg";
import "../../src/menuCategory/category.css";
import { useDispatch, useSelector } from 'react-redux'
import { addProduct, addCart, editCart } from "../Redux/Redux";
import ProductWrap from "../../src/product/productWrap";
import NavHead from "../navbarHead";
import MobileMenu from "../../src/navbarHead/mobileNav";
import "../../src/menuCategory/pahination.css";
import SideBar from "../SideBar";


const MenuCategory = () => {
  const [itemsForSelectedSubcategory, setItemsForSelectedSubcategory] = useState([]);
  const { categoryid } = useParams();
  const [quantities, setQuantities] = useState([]);
  const [errorvalue, setErrorvalue] = useState(null);
  const [errorvalueforimg, setErrorvalueforimg] = useState(null);
  const [helmetDetails, sethelmetDetails] = useState(null);
  const [categories, setCategories] = useState([]);
  const [hasItemsInWishlist, setHasItemsInWishlist] = useState(false);
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [catagory, setCatagory] = useState({});

  const dispatch = useDispatch();
  const wishlist = useSelector(state => state.wishlist.value)
  const cart = useSelector(state => state.cart.value)
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    const storedQuantities =
      JSON.parse(localStorage.getItem("quantities")) || [];
    const storedWishList = JSON.parse(localStorage.getItem("wishlist")) || [];
    setQuantities(storedQuantities);
  }, []);

  useEffect(() => {
    axios
      .put("http://68.178.169.226:12080/api/v1/category/get")
      .then((response) => {
        setCategories(response.data);
         const cat = response.data.find((item) => item.categoryid == categoryid);
      sethelmetDetails(cat.sortdescription + " - " + cat.fulldescription);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  useEffect(() => {
    fetchItemsForSelectedSubcategory();
  }, [categoryid]);

  const fetchItemsForSelectedSubcategory = async () => {
    try {
      setLoading(true);
      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/product/getbyCategory",
        {
          categoryid: categoryid,
          currency: localStorage.getItem('currencyName'),
          page: 0
        }
      );

      if (response.status === 200) {
        const items = response.data;
        setCatagory(items);
        if (Array.isArray(items)) {
          setItemsForSelectedSubcategory(items);
          const cat = categories.find((item) => item.categoryid == categoryid);
          sethelmetDetails(cat.sortdescription + "-" + cat.fulldescription);

        }else{
          
          setItemsForSelectedSubcategory([]);
        }
      } else {
        console.error("API request failed");
      }
      if (response.data.status === "Error") {
        setErrorvalue(response.data.description);
        setErrorvalueforimg(response.data.organiserId);
      }
    } catch (error) {
      console.error("Error making API request:", error);
    }
    finally {
      setLoading(false);
    }
  };

  const fetchPageNumbers = async () => {
    try {
      const formData = new FormData();
      formData.append('field', 'category');
      formData.append('id', categoryid)

      const response = await axios.put(
        'http://68.178.169.226:12080/api/v1/product/page',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      const totalPagesFromResponse = parseFloat(response.data);
      setTotalPages(totalPagesFromResponse);
      if(totalPagesFromResponse <= 1) {
        setPageNumbers([]);
      }else{

      const numbersArray = Array.from({ length: totalPagesFromResponse }, (_, index) => index + 1);
      setPageNumbers(numbersArray);
      }
    } catch (error) {
      console.log('Error fetching page numbers:', error);
    }
  };

  useEffect(() => {
    fetchPageNumbers();
  }, [categoryid]);

  useEffect(() => {
    fetchItemsForSelectedSubcategory();
    fetchItemsForPage(0);
  }, []);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchItemsForPage(pageNumber);
  };

  const fetchItemsForPage = async (pageNumber) => {
    try {
      setLoading(true);
      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/product/getbyCategory",
        {
          categoryid: categoryid,
          currency: localStorage.getItem('currencyName'),
          page: pageNumber,
        }
      );

      if (response.status === 200) {
        const items = response.data;
        if (Array.isArray(items)) {
          setItemsForSelectedSubcategory(items);
          const cat = categories.find((item) => item.categoryid == categoryid);
          sethelmetDetails(cat.sortdescription + "-" + cat.fulldescription);

        }
      } else {
        console.error("API request failed");
      }
      if (response.data.status === "Error") {
        setErrorvalue(response.data.description);
        setErrorvalueforimg(response.data.organiserId);
        setItemsForSelectedSubcategory([]);
      }
    } catch (error) {
      console.error("Error making API request:", error);
    }
    finally {
      setLoading(false);
    }
  };

  const updateProductQuantity = async (productId, operation) => {
    let cartItem = cart.find((cartItem) => cartItem.productid == productId)
    if (operation == 'add') {
      dispatch(editCart({ productId, cartid: cartItem.cartid, newQuantity: parseInt(cartItem.productquantity) + 1 }))
    }
    else {
      dispatch(editCart({ productId, cartid: cartItem.cartid, newQuantity: parseInt(cartItem.productquantity) - 1 }))
    }
  };

  const renderPageNumbers = () => {
    const maxVisiblePages = 4;
    const delta = Math.floor(maxVisiblePages / 2);
  
    if (totalPages <= maxVisiblePages) {
      return pageNumbers.map((number) => (
        <li
          key={number}
          className={currentPage === number - 1 ? "selected-page" : ""}
          onClick={() => handlePageClick(number - 1)}
        >
          {number}
        </li>
      ));
    }
  
    if (currentPage <= delta) {
      return (
        <>
          {pageNumbers.slice(0, maxVisiblePages - 1).map((number) => (
            <li
              key={number}
              className={currentPage === number - 1 ? "selected-page" : ""}
              onClick={() => handlePageClick(number - 1)}
            >
              {number}
            </li>
          ))}
          <li>...</li>
          <li onClick={() => handlePageClick(totalPages - 1)}
          >
            {totalPages}
          </li>
        </>
      );
    }
  
    if (currentPage >= totalPages - delta) {
      return (
        <>
          <li
            style={{ marginRight: '10px', cursor: 'pointer' }}
            onClick={() => handlePageClick(0)}
          >
            1
          </li>
          <li>...</li>
          {pageNumbers.slice(totalPages - maxVisiblePages + 1).map((number) => (
            <li
              key={number}
              className={currentPage === number - 1 ? "selected-page" : ""}
              style={{ marginRight: '10px', cursor: 'pointer' }}
              onClick={() => handlePageClick(number - 1)}
            >
              {number}
            </li>
          ))}
        </>
      );
    }
    return (
      <>
        <li
          style={{ marginRight: '10px', cursor: 'pointer' }}
          onClick={() => handlePageClick(0)}
        >
          1
        </li>
        <li>...</li>
        {pageNumbers.slice(currentPage - delta, currentPage + delta).map((number) => (
          <li
            key={number}
            className={currentPage === number - 1 ? "selected-page" : ""}
            style={{ marginRight: '10px', cursor: 'pointer' }}
            onClick={() => handlePageClick(number - 1)}
          >
            {number}
          </li>
        ))}
        <li>...</li>
        <li
          style={{ marginRight: '10px', cursor: 'pointer' }}
          onClick={() => handlePageClick(totalPages - 1)}
        >
          {totalPages}
        </li>
      </>
    );
  };
  

  const handlePrevPage = () => {
    if (currentPage > 0) {
      handlePageClick(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      handlePageClick(currentPage + 1);
    }
  };

  return (
    <div>
      <div>
        <Helmet>
          <title>{helmetDetails}</title>
        </Helmet>
      </div>
      <Ecomheader />
      <MobileMenu />
        <div className="container homebg ">
        {/* <SideBar categories={catagory} /> */}
          {loading || !itemsForSelectedSubcategory ? (
            <div className="container-fluied">
              <div className="row">
                <div className="muti-col-loader"></div>
              </div>
            </div>
          ) : (
            <div className="row d-flex justify-content-center align-item-center">
              {itemsForSelectedSubcategory.length !== 0 ? (
                itemsForSelectedSubcategory.map((item) => (
                  // <span key={item} >
                    <ProductWrap key={item.productid} product={item} />
                  // </span>
                ))
              ) : (
                <h1 className="notavailable" style={{ display: !itemsForSelectedSubcategory ? 'none' : 'block' }}>
                  <img src={errorvalueforimg} />
                </h1>
              )}
            </div>
          )}
          {loading || !itemsForSelectedSubcategory ? (
            <div className="container-fluied">
               
            </div>
          ) : (
            <>
            
            {totalPages > 1 && (
  <ul className="d-flex justify-content-center pagination">
    <li style={{ marginRight: '10px', cursor: 'pointer' }} onClick={handlePrevPage}>
      {'<'}
    </li>
    {renderPageNumbers()}
    <li style={{ marginRight: '10px', cursor: 'pointer' }} onClick={handleNextPage}>
      {'>'}
    </li>
  </ul>
)}

            </>
          )
          }
        </div>

        <Ecomfooter />
    </div>
  );
};

export default MenuCategory;
