import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { getOrdersListAPI } from "../Redux/Api";
import { updateOrderStateValue } from "../Redux/Redux";
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop";
import MobileMenu from "../../src/navbarHead/mobileNav";

const AccountOrders = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.order.value);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const itemsPerPage = 10;

  const getOrdersCount = async () => {
    try {
      setLoading(true);
      const data = await getOrdersListAPI();
      dispatch(updateOrderStateValue(data));
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrdersCount();
  }, []);

  const filteredOrders = orders.filter(order =>
    order.ordernumber.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sort the orders based on createDate in ascending order
  const sortedOrders = filteredOrders.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));

  // Calculate the orders to display based on the current page
  const indexOfLastOrder = currentPage * itemsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
  const currentOrders = sortedOrders.slice(indexOfFirstOrder, indexOfLastOrder);

  return (
    <div>
      <Ecomheader />
      <MobileMenu />
      <main className="main account container homebg order-datatable">
        <div className="page-content pt-2">
        {loading ? (

<div className="container-fluied">
  <div className="row">
    <div className="muti-col-loader"></div>
  </div>
</div>

) : (
          <div className="">
            <h2 className="title title-center">My Orders</h2>
            <div className="row">
              <div className="tab-content col-lg-12 col-md-12 col-sm-12">
                <div className="d-flex justify-content-end">
                  <input
                    type="text"
                    id="searchInput"
                    className="form-control col-md-4 col-sm-12"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search by Order Number"
                  />
                </div>
              </div>
            </div>
            <div className="tab tab-vertical">
              <div className="tab-content col-lg-12 col-md-12">
                <div className="orders">
                  <div className="tab-pane" id="orders">
                    <table className="order-table">
                      <thead>
                        <tr>
                          <th className="pl-2">Order</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Total</th>
                          <th>Payment Status</th>
                          <th className="pr-2">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentOrders.map((order) => (
                          <tr key={order.orderid}>
                            <td className="order-number">
                              <a href={"/ViewOrder/" + order.orderid}>
                                {order.ordernumber}</a>
                            </td>
                            <td className="order-date">
                              {new Date(order.createDate).toISOString().split('T')[0]}
                            </td>
                            <td className="order-status">
                              <span>{order.orderStatus}</span>
                            </td>
                            <td className="order-total">
                              <span>{order.total}</span>
                            </td>
                            <td className="order-total">
                              <span>{order.paymentstatus}</span>
                            </td>
                            <td className="order-action">
                              <Link to={`/ViewOrder/${order.orderid}`} className="btn btn-primary btn-link btn-underline">
                                View
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="pagination" style={{ textAlign: "end", display: "flex", justifyContent: "flex-end" }}>
              <button
                className="page-link mr-2"
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>

              <span className="page-link mx-2">{currentPage}</span>

              <button
                className="page-link ml-2"
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={indexOfLastOrder >= sortedOrders.length}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
)}
        </div>
      </main>
      <Ecomfooter />
    </div>
  );
};

export default AccountOrders;
