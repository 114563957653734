import React from 'react';
import { Helmet } from 'react-helmet';

function MyComponent() {
  // Logic to determine the URL of the dynamically generated image
  const dynamicImageUrl = 'https://storage.megashop.my/Recom/20240211191043.jpeg';

  return (
    <div>
      {/* Your component content */}
      <Helmet>
        <title>Your Page Title</title>
        <meta property="og:title" content="Your Page Title" />
        <meta property="og:description" content="Description of your page" />
        {/* Dynamically set the og:image meta tag */}
        {dynamicImageUrl && <meta property="og:image" content={dynamicImageUrl} />}
        {/* Add other Open Graph meta tags here */}
      </Helmet>
      {/* Rest of your component */}
    </div>
  );
}

export default MyComponent;