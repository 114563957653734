import axios from "axios";
import BASE_URL from "./config";

export const fetchCategories = async () => {
    try {
        const response = await axios.put(`${BASE_URL}category/get`, {
            currency: localStorage.getItem('currencyName')
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching categories:", error);
        return [];
    }
};

export const fetchClientDetails = async (token) => {
    try {
        const response = await fetch(
            `${BASE_URL}login/getclientdetails`,
            {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const clientData = await response.json();
        return clientData;
    } catch (error) {
        console.error("Error fetching client details:", error);
        return null;
    }
};

export const fetchNotifications = async () => {
    try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${BASE_URL}notification/get`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
                clientid: localStorage.getItem("clientid")
            }),
        });
        if (!response.ok) {
            throw new Error('Failed to fetch notifications');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching notifications:', error);
        throw error;
    }
};
