import React, { useState, useEffect } from "react";
import axios from "axios";
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop";
import backgroundImage from "../../src/images/about-us.jpg";
import "../aboutus/about.css";
import { Helmet } from "react-helmet";
const PrivacyPolicy = () => {
  const [termsConditions, setTermsconditions] = useState("");
  const [commonDetailsId, setCommonDetailsId] = useState(""); // Assuming you have a way to obtain this ID
  const [commondetailsfield, setCommonDetailsField] =
    useState("privacypolicy"); // Adjust as needed
  // Function to fetch common details
  const fetchCommonDetails = async () => {
    try {
      const payload = {
        commondetailsfield: "privacypolicy",
      };
      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/commondetails/getcommondetails",
        payload,
      );
      if (response.status === 200) {
        // Successfully fetched common details
        const commonDetails = response.data;
        setCommonDetailsField(commonDetails);
        // Do something with commonDetails, such as updating your component's state
      } else {
        console.error("Failed to fetch common details");
      }
    } catch (error) {
      console.error("Error fetching common details:", error);
    }
  };

  // useEffect to fetch common details when the component mounts
  useEffect(() => {
    fetchCommonDetails();
  }, []);
  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`, // Use the imported image
  };
  return (
    <div>
    <div>
        <Helmet>
            <title>Privacy Policy</title>
        </Helmet>
    </div>
      <Ecomheader />
      <div
        className="aboutHeader pl-4 pr-4"
      >
        <h3 className="page-subtitle font-weight-bold">Welcome to Shop Easey</h3>
        <h1 className="page-title font-weight-bold lh-1 text-white text-capitalize">
          Our Privacy Policy
        </h1>
        </div>
        <p className="page-desc container  pt-10 pb-3" dangerouslySetInnerHTML={{ __html: commondetailsfield.commondetails }}></p>
      <Ecomfooter />
    </div>
  );
};
export default PrivacyPolicy;
