import React from 'react';
import '../theme.css';
import   Float_offer1  from "../../../src/images/offer-banner.png";
import { Link } from 'react-router-dom';
import Megashophome from '../../banner/insex';

const FirstTimeVoucher = ({ onCloseMegashophome, onCloseTheme, isMegashophomeVisible }) => {
  const handleClose = () => {
    // Call the callback function to hide Megashophome component
    onCloseMegashophome();
  };

  return (
      <div className="offer-banner">
        <img src={Float_offer1}/>
        
    </div>
  );
};

export default FirstTimeVoucher;
