import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Ecomheader from "../../src/header/headershop";
import Ecomfooter from "../footer/footershop";
import { Helmet } from "react-helmet";
import MobileMenu from "../../src/navbarHead/mobileNav";
import { Modal, Input, Button } from 'antd'
import './style.css'
const ViewOrder = () => {

  const { orderid } = useParams();
  const [orderData, setOrderData] = useState(null);
  const [orderId, setOrderId] = useState('');
  const [Clientcancellreason, setClientcancellreason] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const payload = {
        orderid: orderid  ? parseInt(orderid):parseInt(localStorage.getItem('selectedOrder')),
      };
      const response = await axios.post(
        "http://68.178.169.226:12080/api/v1/order/getbyid",
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 401 || response.status === 404) {
        navigate('/login');
        return;
      }
      setOrderData(response.data);
      setOrderId(response.data.orderid);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const cancelOrderClick = async () => {
    try {
      const payload = {
        orderid: orderId,
        clientid: parseInt(localStorage.getItem('clientid')),
        orderStatus: 'Cancelled',
        clientcancellreason: Clientcancellreason,
      };
      const response = await axios.post('http://68.178.169.226:12080/api/v1/order/buyercancellation',
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            'Content-Type': 'application/json',
          },
        }
      );
      fetchData();
      if (response.status === 401 || response.status === 404) {
        navigate('/login');
        return;
      }
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error', error);
    }
  }
  const showModal = () => {
    setIsModalVisible(true);
  }
  const handleCancel = () => {
    setIsModalVisible(false);
  }
  const handleReasonCancel = (e) => {
    setClientcancellreason(e.target.value);
  }
  return (
    <div>
      <div>
        <Helmet>
          <title>Order Summary</title>
        </Helmet>
      </div>
      <Ecomheader />
      <MobileMenu />
      <div class="">
        <div class="container homebg d-flex justify-content-center pt-5">
          
          <div className="col-md-10 col-lg-10 col-sm-12    order-summery">
            <div className="row">
              <div className="col-sm-12 col-md-12 "><h2 class="title title-center mb-5">Order Summary</h2></div>
            </div>
            <div className="row d-flex justify-content-end">
              <div className="order-cancel">
                {orderData && orderData.orderStatus !== 'Cancelled' && orderData.orderStatus !== 'CancelledByBuyer' &&
                  <button onClick={showModal}>Cancel</button>
                }
              </div></div>
              {orderData && 
            <div className="row status-bar d-flex justify-content-between">
              
              <div ><span> Order #: {orderData.ordernumber},  {new Date(orderData.createDate).toLocaleDateString()} </span></div>
              <div > <span> Payment Method :{orderData.paymentmethod === "cod" ? 'Cash on Delivery' : 'Online'}</span></div>
              <div > <span> Status :{orderData.orderStatus}</span></div>
             
            </div>
            }
            <div className="row">
              <div className="col-md-6 col-sm-12 mt-2">
                <h5>Billing Address:</h5>
                {orderData && orderData.billingaddress && (
  <div>
    <h2>{orderData.billingaddress.split(',')[0]}</h2>
    <p>
      {orderData.billingaddress
        .split(',')
        .slice(1)
        .join(', ')}
    </p>
  </div>
)}

              </div>
              <div className="col-md-6 col-sm-12  mt-2">
                <h5>Shipped Address:</h5>
                <p>
  {orderData && (
    <div>
                                    <h2>{orderData.shippingaddress.name ? orderData.shippingaddress.name : ''}</h2>
                                    <p>  {orderData.shippingaddress.address1 && `${orderData.shippingaddress.address1}, `}
  {orderData.shippingaddress.address2 && `${orderData.shippingaddress.address2}, `}
  {orderData.shippingaddress.city && `${orderData.shippingaddress.city}-`}
  {orderData.shippingaddress.pincode && `${orderData.shippingaddress.pincode}`}

                                    </p>
    </div>
  )}
</p>
<p><strong>Estimated Deliver Date 6-8 days</strong></p>
<p><strong>{orderData && orderData.createDate && 
  `${new Date(new Date(orderData.createDate).getTime() + (6 * 24 * 60 * 60 * 1000)).toLocaleDateString('en-US', {  year: 'numeric', month: 'short', day: '2-digit' })} - 
  ${new Date(new Date(orderData.createDate).getTime() + (8 * 24 * 60 * 60 * 1000)).toLocaleDateString('en-US', {  year: 'numeric', month: 'short', day: '2-digit' })}`}</strong></p>



              </div>
              
            </div>
            {orderData && orderData.products.map((product, index) => ( 
            <div className="row ordered-product">
              <div className="col-md-2  col-sm-0 view-order-img">
              <Link to={`/Product/${product.productid}`}>
                {product.previewimg && (
                  <img className="product-img" src={product.previewimg.file} alt={product.productname}   />
                )}
                </Link>
              </div>
              <div className="col-md-6 col-sm-6">
              <Link to={`/Product/${product.productid}`}>
              <p>{product.productname}</p>
              </Link>
              <Link to={`/SellerProduts/${product.sellerid}`}>
  <p className="soldby"> Sold by: {product.sellername}</p>
</Link>

              </div>
              <div className="col-md-2 col-sm-2">
              {product.productcount}&nbsp;
              <span>
                Qty</span>
              </div>
              <div className="col-md-2  col-sm-4 text-right">
              {orderData.currency} {orderData && parseFloat(orderData.total).toFixed(2)}
              </div>
            </div>
            ))}
            <div className="row d-flex justify-content-end order-footer">
            <div className="col-md-4  col-sm-12">
                 
                <div className="d-flex justify-content-between">
                  <div >
                    <p> Subtotal</p>
                    <p> Delivery</p>
                    <p> Offer</p>
                    <p> Grand Total</p>
                  </div>
                  <div>
                    {orderData && 
                    <>
                    <p> {orderData.currency}{orderData.subtotal}</p>
                    <p>{orderData.deliverycharge ? `${orderData.currency}${orderData.deliverycharge}` : '-'}</p>
                    
<p>{orderData.cuponvalue ? `${orderData.currency}${orderData.cuponvalue}` : '-'}</p>

                    <p> {orderData.currency}{orderData && parseFloat(orderData.total).toFixed(2)}</p>
                    </>
}
                  </div>
                </div>

              </div>
              </div>

          </div>



         
        </div>
      </div>
      <Ecomfooter />
      <Modal
        title="Cancel Order"
        visible={isModalVisible}
        onOk={cancelOrderClick}
        onCancel={handleCancel}
      >
        <Input placeholder="Enter Reason for Cancellation" onChange={handleReasonCancel} />
      </Modal>
    </div>
  );
};

export default ViewOrder;