import React, { useEffect, useState } from 'react';
import $ from 'jquery'; // Import jQuery
import './productGallery.css'; // Import CSS file or define styles in JSX

const ProductGallery = ({ noimage, previewImage, fileModelImages, youTube, handleColorHover  }) => {
  const allImages = [
    ...(previewImage ? [{ file: previewImage }] : []),
    ...(fileModelImages || []),
    ...(youTube ? [{ youTube }] : [])
  ];
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [imageClasses, setImageClasses] = useState();
  const [startIndex, setStartIndex] = useState(0);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  useEffect(() => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    };

    const loadAllImages = async () => {
      try {
        const loadedImages = await Promise.all(
          allImages.map((image) => loadImage(image.file || image.youTube))
        );
        setImagesLoaded(true);
        setImageClasses(
          loadedImages.map((img) => {
            const { width, height } = img;
            let className = 'slideshow-items';
            if (width > height) {
              className += ' img-horizontal';
            } else if (width === height) {
              className += ' img-horizontal';
            } else {
              className += ' img-vertical';
            }
            return className;
          })
        );
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    loadAllImages();
  }, [allImages]);

  useEffect(() => {
    // Function to handle image zoom
    const imageZoom = (img, result, lens) => {
      result.width(img.innerWidth());
      result.height(img.innerHeight());
      lens.width(img.innerWidth() / 2);
      lens.height(img.innerHeight() / 2);

      result.offset({ top: img.offset().top, left: img.offset().left + img.outerWidth() + 10 });

      const cx = img.innerWidth() / lens.innerWidth();
      const cy = img.innerHeight() / lens.innerHeight();

      result.css('backgroundImage', 'url(' + img.attr('src') + ')');
      result.css('backgroundSize', img.width() * cx + 'px ' + img.height() * cy + 'px');

      lens.mousemove(function (e) {
        moveLens(e);
      });
      img.mousemove(function (e) {
        moveLens(e);
      });
      lens.on('touchmove', function () {
        moveLens();
      });
      img.on('touchmove', function () {
        moveLens();
      });

      function moveLens(e) {
        let x = e.clientX - lens.outerWidth() / 2;
        let y = e.clientY - lens.outerHeight() / 2;
        if (x > img.outerWidth() + img.offset().left - lens.outerWidth()) {
          x = img.outerWidth() + img.offset().left - lens.outerWidth();
        }
        if (x < img.offset().left) {
          x = img.offset().left;
        }
        if (y > img.outerHeight() + img.offset().top - lens.outerHeight()) {
          y = img.outerHeight() + img.offset().top - lens.outerHeight();
        }
        if (y < img.offset().top) {
          y = img.offset().top;
        }
        lens.offset({ top: y, left: x });
        result.css('backgroundPosition', '-' + (x - img.offset().left) * cx + 'px -' + (y - img.offset().top) * cy + 'px');
      }
    };

    // Initialize on component mount
    $(document).ready(() => {
      $(document).mousemove((e) => {
        const x = e.clientX;
        const y = e.clientY;
        const activeSlide = $('.slideshow-items.active');

        if (activeSlide.length > 0) {
          const imgx1 = activeSlide.offset().left;
          const imgx2 = activeSlide.outerWidth() + imgx1;
          const imgy1 = activeSlide.offset().top;
          const imgy2 = activeSlide.outerHeight() + imgy1;

          if (x > imgx1 && x < imgx2 && y > imgy1 && y < imgy2) {
            $('#lens').show();
            $('#result').show();
            imageZoom(activeSlide, $('#result'), $('#lens'));
          } else {
            $('#lens').hide();
            $('#result').hide();
          }
        }
      });
    });
  }, [imagesLoaded]);

  const goToPreviousThumbnail = () => {
    if (startIndex > 0) {
      setStartIndex((prevIndex) => prevIndex - 1);
    }
  };

  const goToNextThumbnail = () => {
    if (startIndex < allImages.length - 4) {
      setStartIndex((prevIndex) => Math.min(prevIndex + 1, allImages.length - 4));
    }
  };
  
  useEffect(() => {
    $(document).ready(() => {
      $(document).mousemove((e) => {
        const x = e.clientX;
        const y = e.clientY;
        const thumbnails = $('.slideshow-thumbnails');

        thumbnails.each(function (index) {
          const thumbnailRect = this.getBoundingClientRect();
          if (
            x >= thumbnailRect.left &&
            x <= thumbnailRect.right &&
            y >= thumbnailRect.top &&
            y <= thumbnailRect.bottom
          ) {
            setActiveImageIndex(startIndex + index);
            return false; // Break the loop
          }
        });
      });
    });
  }, [startIndex]);
  return (
    <div className='col-md-6 col-lg-4  col-sm-12'>
      <div id='lens'></div>
      <div id='slideshow-items-container' className='product-image d-flex justify-content-center'>
  {imagesLoaded &&
    allImages.map((image, index) => (
      <img
        key={index}
        className={`${index === activeImageIndex ? 'active' : ''} product-image ${imageClasses[index]}`}
        src={image.file || image.youTube}
      />
    ))}
</div>


      <div id='result'></div>
<div className='thumb'>
      <div className='row'>
        <div className='col-md-1 col-sm-1 d-flex align-items-center'>
        {allImages.length > 4 && (
          <button className={`r-icon r-icon-previous ${startIndex === 0 ? 'disabled' : ''}`} onClick={goToPreviousThumbnail}></button>
        )}
        </div>
        <div className='col-md-10 col-sm-10 d-flex justify-content-center'>
          <div>
        {imagesLoaded &&
          allImages.slice(startIndex, startIndex + 4).map((image, index) => (
            <img
              key={index}
              className={`slideshow-thumbnails ${startIndex + index === activeImageIndex ? 'active' : ''}`}
              src={image.file || image.youTube}
              onMouseEnter={() => {
                setActiveImageIndex(startIndex + index);
              }}
            />
          ))}
          </div>
          </div>
          <div className='col-md-1 col-sm-1 d-flex align-items-center'>
        {allImages.length > 4 && (
          <button className={`r-icon r-icon-next ${startIndex === allImages.length - 4 ? 'disabled' : ''}`} onClick={goToNextThumbnail}></button>
        )}
      </div>
      </div>
</div>
    </div>
  );
};

export default ProductGallery;
