import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import noimage from '../../src/images/noimage.jpg';
import { useDispatch, useSelector } from "react-redux";
import { addCart, addProduct, editCart } from "../Redux/Redux";
import ProductWrap from "../../src/product/productWrap";
const Megashopourbest = () => {
  // State to hold the banner images
  const [bestImages, setBestImages] = useState([]);
  // const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart.value)
  const wishlist = useSelector(state => state.wishlist.value)

  


  // Function to fetch banner images
  const fetchBestImages = async () => {
    try {
      const payload = {
        currency: localStorage.getItem('currencyName')
      };
      const response = await axios.put("http://68.178.169.226:12080/api/v1/product/getbestsellers", payload,{
      
      headers: {
        // 'Authorization': 'Bearer ' +token,
        'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        const data = response.data.data;
        setBestImages(data.slice(0,50)); 
      } else {
        console.error('Failed to fetch offer images');
      }
    } catch (error) {
      console.error('Error fetching offer images:', error);
    }
  };

  // Fetch banner images when the component mounts
  useEffect(() => {
    fetchBestImages();
  }, []);


  const updateProductQuantity = async (productId, operation) => {
    let cartItem = cart.find((cartItem) => cartItem.productid == productId)
    if (operation == 'add') {
      dispatch(editCart({ productId, cartid: cartItem.cartid, newQuantity:parseInt(cartItem.productquantity) + 1 }))
    }
    else {
      dispatch(editCart({ productId, cartid: cartItem.cartid, newQuantity:parseInt(cartItem.productquantity) - 1 }))
    }
  };

  return (
    bestImages.length > 0 && (
    <section class={bestImages.length == 0 ? 'hidden' : "container" }>
        {  <div className="row">
      <div className=" col-12">
            <h2 class="section-title">Latest Product</h2>
            <a href="/BestSellers" class="section-link">View More</a>
          </div>
      </div>  }

          
        <div className="row">
        
          {/* <h2 class="title title-center mb-5">Best Sellers</h2> */}
          {bestImages.map((product, index) => (
            // <span key={index} class="">
             <ProductWrap key={product.productid} product={product} /> 
            // </span>
          ))}
        </div>
    </section>
    )
  )
}
export default Megashopourbest;