import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import _ from 'lodash';
import Select from "react-select";

const OnlyBilling = ({ billingaddress }) => {
  const mobilePrefix = localStorage.getItem('mobileprefix');
  const countrySelection = localStorage.getItem('selectedCountry');
  const navigate = useNavigate();
  const [clientDetails, setClientDetails] = useState(null);
  const [fname, setfname] = useState("");
  const [companyname, setcompanyname] = useState("");
  const [country, setcountry] = useState("");
  const [region, setregion] = useState("");
  const [address1, setaddress1] = useState("");
  const [address2, setaddress2] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [pincode, setpincode] = useState("");
  const [phonenumber, setphonenumber] = useState("");
  const [email, setemail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const isBillingAddressEmpty =
    !billingaddress || Object.keys(billingaddress).length === 0;
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [pincodeList, setPincodeList] = useState([]);
  const [mobileprefixforChange, setMobilePrefix] = useState('');
  const [mobilemaxlengthforChange, setMobileMaxLength] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const handleCountryClick = (selectedValue) => {
    // You can use the selectedValue as the selected country here
    setcountry(selectedValue);
    setSelectedState("");
    // Make the API call to fetch states based on the selected country
    if (selectedValue) {
      fetchStates(selectedValue);
    }
  };
  
  const handleStateClick = (selectedValue) => {
    // You can use the selectedValue as the selected country here
    setstate(selectedValue);
    setSelectedState(selectedValue);
    // Make the API call to fetch states based on the selected country
    if (selectedValue) {
      fetchPincodes(country, selectedValue);
    }
  };
  const handleSubmit = async (e) => {

    e.preventDefault();
    setErrorMessage("")
    
    const validations = [
      { value: fname, message: "Name should be mandatory" },
      { value: phonenumber, message: "Phonenumber should be mandatory" },
      { value: email, message: "Email should be mandatory" },
      { value: address1, message: "Street should be mandatory" },
      { value: country, message: "Country should be mandatory" },
      { value: state, message: "State should be mandatory" },
      { value: city, message: "City should be mandatory" },
      { value: pincode.toString(), message: "Zip should be mandatory" },
    ];
    
    for (const validation of validations) {
      if (!validation.value) {
        setErrorMessage(validation.message);
        return;
      }
    }
    
    if (phonenumber.length < + parseInt(localStorage.getItem('mobilemaxlength'))) {
      // Show an error message for less than 10 digits
      setErrorMessage("Please enter" +  localStorage.getItem('mobilemaxlength') + "digits.");
      return;
    }
    
    try {
      const response = await fetch(
        "http://68.178.169.226:12080/api/v1/client/editbillingaddress",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            clientid: clientDetails.id,
            name: fname,
            companyname,
            country,
            address1,
            region,
            address2,
            city,
            state,
            pincode,
            phonenumber,
            email,
          }),
        }
      );

      if (response.ok) {
        // Billing address updated successfully
        // You may want to show a success message or perform other actions
        // window.location.reload();
        // Update the addressBilling state with editedBillingAddress
        navigate(0);
      } else {
        console.error("Failed to update billing address");
      }
    } catch (error) {
      console.error("Error updating billing address:", error);
    }
  };

  useEffect(() => {
    // Function to fetch client details
    const fetchClientDetails = async () => {
      try {
        // Make the API request to get client details
        const response = await fetch(
          "http://68.178.169.226:12080/api/v1/login/getclientdetails",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          // Set the client details in state
          setClientDetails(data);

          // After receiving client details, make another API request
          // to fetch address billing using the id from the client details
          fetchAddressBilling(data.id);
        } else {
          console.error("Failed to get client details");
        }
      } catch (error) {
        console.error("Error fetching client details:", error);
      }
    };

    // Function to fetch address billing
    const fetchAddressBilling = async (id) => {
      try {
        // Define the payload with the client ID
        const payload = {
          id: id,
        };

        // Make the API request to get address billing with the payload
        const response = await fetch(
          "http://68.178.169.226:12080/api/v1/client/getbillingaddress",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(payload),
          }
        );

        if (response.ok) {
          const data = await response.json();
          // Set the address billing data in state
          setfname(data.name);
          setcountry(data.country);
          setcompanyname(data.companyname);
          setregion(data.region);
          setaddress1(data.address1);
          setaddress2(data.address2);
          setcity(data.city);
          setstate(data.state);
          setpincode(data.pincode);
          setphonenumber(data.phonenumber);
          setemail(data.email);
          setcountry(data.country);
          fetchStates(data.country);
          fetchPincodes(data.country, data.state);
          setSelectedState(data.state);
        } else {
          console.error("Failed to get address billing");
        }
      } catch (error) {
        console.error("Error fetching address billing:", error);
      }
    };

    // Call the fetchClientDetails function when the component mounts
    fetchClientDetails();
  }, []);
  const handleMobileChange = (e) => {
    const newValue = e.target.value;
    const maxLength = selectedCountries ? mobilemaxlengthforChange : parseInt(localStorage.getItem('mobilemaxlength'));
    // Check if the input consists of only digits and has a maximum length of 10 characters
    if (/^\d*$/.test(newValue) && newValue.length <= maxLength) {
      setphonenumber(newValue);
      setErrorMessage("");
    } else {
      // Set an error message when the input is invalid
      setErrorMessage(
        "Phone number must be a maximum of" +  localStorage.getItem('mobilemaxlength') + "digits and contain only numbers."
      );
    }
  };
  useEffect(() => {
    const fetchCountryList = async () => {
      try {
        const response = await fetch(
          "http://68.178.169.226:12080/api/v1/pincode/getcountry",
          {
            method: "PUT",
          }
        );

        if (response.ok) {
          const data = await response.json();
          setCountryList(data);
        } else {
          console.error("Failed to get the list of countries");
        }
      } catch (error) {
        console.error("Error fetching the list of countries:", error);
      }
    };

    fetchCountryList();
  }, []);

  const fetchStates = async (country) => {
    try {
      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/pincode/getstate",
        {
          country,
        }
      );

      if (response.status === 200) {
        setStateList(response.data);
      } else {
        console.error("Failed to get the list of states");
      }
    } catch (error) {
      console.error("Error fetching the list of states:", error);
    }
  };
  const fetchPincodes = async (country, selectedState) => {
    try {
      const response = await axios.put(
        "http://68.178.169.226:12080/api/v1/pincode/getpincode",
        {
          country,
          state: selectedState,
        }
      );

      if (response.status === 200) {
        setPincodeList(response.data);
      } else {
        console.error("Failed to get the list of pincodes");
      }
    } catch (error) {
      console.error("Error fetching the list of pincodes:", error);
    }
  };
  const handlePincodeChange = (selectedOption) => {
    setpincode(selectedOption.value);
  };
  const handleCancel = () => {
    navigate(0);
  };
  useEffect(() => {
    const fetchCountryrDetails = async () => {
      try {
        const response = await axios.put('http://68.178.169.226:12080/api/v1/country/get', {});

        if (response.status === 200) {
          const data = response.data;
          setCountriesList(data);
        } else {
          console.error('Failed to get country details');
        }
      } catch (error) {
        console.error('Error fetching country details:', error);
      }
    };

    // Call the function to fetch user details
    fetchCountryrDetails();
  }, []);
  useEffect(() => {
      setSelectedCountries(selectedCountries);
  }, []);
  const [selectedCountries, setSelectedCountries] = useState(null);
  const handleCountrySelect = (selectedCountries) => {
    const selectedCountryData = countriesList.find(countryData => countryData.country === selectedCountries);
    if (selectedCountryData) {
      const { symbol, currencycode, mobilemaxlength, mobileprefix } = selectedCountryData;
      setSelectedCountries(selectedCountries);
        setMobilePrefix(mobileprefix);
      setMobileMaxLength(mobilemaxlength);
    }
    else {
      console.error(`Data for ${selectedCountries} not found in the countryList`);
    }
  }
  
  return (
    <div>
 
 <div className="container-fluied">
            <form action="#" className="form">
             
               

                  <div className="row">
                    <div className="col-md-6 col-sm-12 mb-5">
                      <label>Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        required
                        value={fname != null ? fname : ""}
                        onChange={(e) => setfname(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-5">
                      <label>Company Name (Optional)</label>
                      <input
                        type="text"
                        className="form-control"
                        name="company-name"
                        // required
                        value={companyname != null ? companyname : ""}
                        onChange={(e) => setcompanyname(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12  mb-5">
                      <label>Phone *</label>
                      {/* <select
      className="form-control"
      value={selectedCountries}
      onChange={(e) => handleCountrySelect(e.target.value)}
    >
      {countriesList.map((countryData) => (
        <option key={countryData.countryselectionid} value={countryData.country}>
          {countryData.country}{countryData.mobileprefix}
        </option>
      ))}
    </select> */}
                      <div className="input-group-prepend">
                          <span className="input-group-text"> {selectedCountries ? mobileprefixforChange : mobilePrefix}</span>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        required
                        value={phonenumber != null ? phonenumber : ""}
                        onChange={handleMobileChange}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 mb-5">
                      <label>Email Address *</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email-address"
                        required
                        value={email != null ? email : ""}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12 mb-5">
                      <label>Street Address 1 *</label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="address1"
                        required
                        placeholder="House number and street name"
                        value={address1 != null ? address1 : ""}
                        onChange={(e) => setaddress1(e.target.value)}
                        style={{resize: "none"}}
                      />
                      </div>
                      <div className="col-md-6 col-sm-12 mb-5">
                      <label>Street Address 2 *</label>
                      <textarea
                        type="text"
                        className="form-control"
                        name="address2"
                        // required
                        placeholder="Apartment, suite, unit, etc. (optional)"
                        value={address2 != null ? address2 : ""}
                        onChange={(e) => setaddress2(e.target.value)}
                        style={{resize: "none"}}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12 mb-5">
                      <label>Country</label>
                      <select
                        className="form-control"
                        name="country"
                        required
                        value={country}
                        onChange={(e) => handleCountryClick(e.target.value)}
                      >
                        <option value=""></option>
                        {countryList.map((country) => (
                          <option
                            key={country.pincodeid}
                            value={country.country}
                          >
                            {country.country}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 col-sm-12 mb-5">
                      <label>State *</label>
                      
                      <Select
            options={stateList.map((stateItem) => ({
              value: stateItem.state,
              label: stateItem.state,
            }))}
            value={selectedState ? { value: selectedState, label: selectedState } : null}
  onChange={(selectedOption) => handleStateClick(selectedOption.value)}
  isSearchable={true} // Enable search option
  placeholder={selectedState ? selectedState : "Select State"}
          />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-sm-12 mb-5">
                      <label>Town / City *</label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        required
                        value={city != null ? city : ""}
                        onChange={(e) => setcity(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6 col-sm-12">
                      <label>ZIP *</label>
                      <Select
                options={pincodeList.map((pincode) => ({
                  value: pincode.pincode,
                  label: pincode.pincode,
                }))}
                value={{ value: pincode, label: pincode }}
                onChange={handlePincodeChange}
              />
                    </div>
                  </div>

                 
             

              <div className="text-red-600 my-2 mb-3 text-lg" >{errorMessage}</div>
              <>
              <div className="row d-flex justify-content-center">
              <div className="col-lg-3 col-sm-6">
                <button
              type="button"
              className="common-btn mb-5"
              onClick={handleCancel}
            >
              Cancel
            </button>
            </div>
            <div className="col-lg-3 col-sm-6">
            <button
                  type="submit"
                  className="common-btn mb-2"
                  // style={{ width: "20%" }}
                  onClick={handleSubmit}
                >
                  {isBillingAddressEmpty ? "Add" : "Submit"}
                </button>
                </div>
                </div> 
              </>
            </form>
            </div>
         
    </div>
  );
};
export default OnlyBilling;
