import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../src/navbarHead/navmenu.css";
import { Link } from 'react-router-dom';
import "../../src/header/fonticon.css";
const MobileMenu = () => {
  const token = localStorage.getItem('token');
  const [categories, setCategories] = useState([]);
  const [activeTab, setActiveTab] = useState("tab-11");
  const [expandedItems, setExpandedItems] = useState([]);
  const [expandedSubcategories, setExpandedSubcategories] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showCountryList, setShowCountryList] = useState(false);
  const [countryList, setCountryList] = useState([]);


  const handleCloseClick = () => {
    // Remove the class 'mmenu-active' from the body element
    document.body.classList.remove("mmenu-active");
  };
  const handleTabClick = async (tabId) => {
    setActiveTab(tabId);

    try {
      if (tabId === "tab-11") {
        // Main Menu (tab-11) clicked, fetch user details
        const userResponse = await fetch('http://68.178.169.226:12080/api/v1/login/getclientdetails', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (userResponse.ok) {
          const userData = await userResponse.json();

          // Check if firstname is present
          if (userData.firstname) {
            setUserDetails(userData);
          } else {
            // Handle case where firstname is not present (not logged in)
            setUserDetails({});
          }
        } else {
          console.error('Failed to get client details');
        }
      } else if (tabId === "tab-21" && categories.length === 0) {
        // Category Menu (tab-21) clicked, fetch categories
        const categoryResponse = await axios.put("http://68.178.169.226:12080/api/v1/product/getcategoryAndsubcategory", {
          currency: localStorage.getItem('currencyName')
        });

        if (categoryResponse.status === 401 || categoryResponse.status === 404) {
          return;
        }

        setCategories(categoryResponse.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handleToggleClick = (itemId) => {
    setExpandedItems((prevExpandedItems) => {
      return prevExpandedItems.includes(itemId)
        ? prevExpandedItems.filter((item) => item !== itemId)
        : [...prevExpandedItems, itemId];
    });
  };
  const handleSubcategoryToggleClick = (subcategoryId) => {
    setExpandedSubcategories((prevExpandedSubcategories) => {
      return prevExpandedSubcategories.includes(subcategoryId)
        ? prevExpandedSubcategories.filter((id) => id !== subcategoryId)
        : [...prevExpandedSubcategories, subcategoryId];
    });
  };
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.put("http://68.178.169.226:12080/api/v1/product/getcategoryAndsubcategory", {
          currency: localStorage.getItem('currencyName')
        });
        if (response.status === 401 || response.status === 404) {
          return;
        }
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await fetch('http://68.178.169.226:12080/api/v1/login/getclientdetails', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const clientData = await response.json();
          setUserDetails(clientData); // Store user details in state
        } else {
          console.error('Failed to get client details');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    // Call the function to fetch user details
    fetchUserDetails();
  }, []);
  const handleLogout = () => {
    // Remove the token from localStorage
    localStorage.removeItem('token');
    localStorage.clear();
    sessionStorage.clear();

    const currentURL = window.location.href;
    if (currentURL.includes('HomePage')) {
      window.location.reload(false)
    }
    else {
      // navigate("/HomePage");
    }
  };
  useEffect(() => {
    const fetchCountryrDetails = async () => {
      try {
        const response = await axios.put('http://68.178.169.226:12080/api/v1/country/get', {});

        if (response.status === 200) {
          const data = response.data;
          setCountryList(data);
        } else {
          console.error('Failed to get country details');
        }
      } catch (error) {
        console.error('Error fetching country details:', error);
      }
    };

    // Call the function to fetch user details
    fetchCountryrDetails();
  }, []);
  useEffect(() => {
    // Load selected country from local storage on component mount
    const storedCountry = localStorage.getItem('selectedCountry');
    if (storedCountry) {
      setSelectedCountry(storedCountry);
    }
  }, []);

  const handleCountrySelect = (selectedCountry) => {
    const selectedCountryData = countryList.find(countryData => countryData.country === selectedCountry);
    if (selectedCountryData) {
      const { symbol, currencycode } = selectedCountryData;
      const confirmChange = window.confirm(`Are you sure you want to change the country to ${selectedCountry}?`);
      if (confirmChange) {
        setSelectedCountry(selectedCountry);
        localStorage.setItem('selectedCountry', selectedCountry);
        localStorage.setItem('currencySymbol', symbol);
        localStorage.setItem('currencyName', currencycode);
      }
      window.location.href = '/HomePage';
    }
    else {
      console.error(`Data for ${selectedCountry} not found in the countryList`);
    }
  }
  return (
    <div class="mobile-menu-wrapper">
      <div class="mobile-menu-overlay">
      </div>
      <a class="mobile-menu-close" href="#" onClick={handleCloseClick}><i class="d-icon-times"></i></a>
      <div class="mobile-menu-container scrollable">


        <div class="tab tab-nav-simple tab-nav-center tab-nav-boxed">
          <ul class="nav nav-tabs nav-fill" role="tablist">
            <li class="nav-item">
              <a
                href="#tab-11"
                className={`nav-link ${activeTab === "tab-11" ? "active" : ""}`}
                onClick={() => handleTabClick("tab-11")}
              >Main Menu</a>
            </li>
            <li class="nav-item">
              <a
                href="#tab-21"
                className={`nav-link ${activeTab === "tab-21" ? "active" : ""}`}
                onClick={() => handleTabClick("tab-21")}
              >Category Menu</a>
            </li>
          </ul>
        </div>
        <div class="mobile-menu mmenu-anim tab-content">
          <div class={`tab-pane ${activeTab === "tab-11" ? "active in" : ""}`} id="tab-11">
            {userDetails.firstname ? (
              <div className="mb-1">
                <span>Welcome {userDetails.firstname}</span>
                <div className="mb-1">
                  <Link to="/UserProfile" onClick={handleCloseClick}>Address</Link>
                </div>
                <div className="mb-1">
                  <Link to="/AccountOrders" onClick={handleCloseClick}>Orders</Link>
                </div>
                <div className="mb-1">
                  Country

                </div>
                {countryList.map((countryData) => (
                  <option key={countryData.countryselectionid} value={countryData.country} onClick={() => handleCountrySelect(countryData.country)}>
                    {countryData.country}
                  </option>
                ))}
                {showCountryList && (
                  <div>

                  </div>
                )}

                <div className="mb-1">
                  <Link to="/HomePage" onClick={() => { handleLogout(); handleCloseClick(); }}>
                    LOGOUT
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <div className="mb-2">
                  <Link to="/LoginPage" onClick={handleCloseClick}>Sign In/Register</Link>
                </div>
                <div>
                  Country

                  {countryList.map((countryData) => (
                    <option key={countryData.countryselectionid} value={countryData.country} onClick={() => handleCountrySelect(countryData.country)}>
                      {countryData.country}
                    </option>
                  ))}
                </div>


              </div>
            )}
          </div>

          <div class="tab-pane active in" id="tab-21">
            <ul>
              {categories.map((category, index) => {
                const isExpanded = expandedItems.includes(`menu-item-${index}`);
                return (
                  <li key={index} className={isExpanded ? "show" : ""}>
                    <a href="#" onClick={(e) => { e.preventDefault(); handleToggleClick(`menu-item-${index}`); }}>
                      <Link to={`/MenuCategory/${category.categoryid}`} onClick={handleCloseClick}>
                        {category.categoryname}</Link>
                      {category.subcategory && category.subcategory.length > 0 && (
                        <span className="r-icon r-icon-down-arrow"></span>
                      )}
                    </a>
                    {category.subcategory && category.subcategory.length > 0 && (
                      <ul style={{ display: isExpanded ? 'block' : 'none' }}>
                        {category.subcategory.map((subcategory, subIndex) => {
                          const subcategoryId = `submenu-item-${index}-${subIndex}`;
                          const isSubcategoryExpanded = expandedSubcategories.includes(subcategoryId);
                          return (
                            <li key={subIndex} className={isSubcategoryExpanded ? "show" : ""}>
                              <a href="#" onClick={(e) => { e.preventDefault(); handleSubcategoryToggleClick(subcategoryId); }}>
                                <Link to={`/MenuSub/${subcategory.subcategoryid}`} onClick={handleCloseClick}>
                                  {subcategory.subcategoryname}</Link>
                                <span className="r-icon r-icon-down-arrow"></span>
                              </a>
                              {subcategory.subsubcategory && subcategory.subsubcategory.length > 0 && (
                                <ul style={{ display: isSubcategoryExpanded ? 'block' : 'none' }}>
                                  {subcategory.subsubcategory.map((subsubcategory, subsubIndex) => (
                                    <li key={subsubIndex}>
                                      <a href="#"><Link to={`/MenuSubSubCategory/${subsubcategory.subsubcategoryid}`} onClick={handleCloseClick}>{subsubcategory.subsubcategoryname}</Link></a>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            </ul>

          </div>
        </div>
      </div>
    </div>
  );
}
export default MobileMenu;