import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Ecomfooter from "../footer/footershop";
import Ecomheader from "../../src/header/headershop";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { followersByCientListAPI } from "../Redux/Api";
import { updatefollowersByClientStateValue } from "../Redux/Redux";
import MobileMenu from "../../src/navbarHead/mobileNav";
const Followers = () => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.followersByClient.value);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true); // Loading state
  const itemsPerPage = 10;
  const getfollowersByClientCount = async () => {
    try {
      setLoading(true);
      const data = await followersByCientListAPI();
      dispatch(updatefollowersByClientStateValue(data));
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getfollowersByClientCount();
  }, []);
  const filteredOrders = orders.filter(order =>
    order.sellercode.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sort the orders based on createDate in ascending order
  const sortedOrders = filteredOrders.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));

  // Calculate the orders to display based on the current page
  const indexOfLastOrder = currentPage * itemsPerPage;
  const indexOfFirstOrder = indexOfLastOrder - itemsPerPage;
  const currentOrders = sortedOrders.slice(indexOfFirstOrder, indexOfLastOrder);
  return (
    <div>
      <Ecomheader />
      <MobileMenu/>
        <main className="main account container homebg order-datatable">
          <nav className="breadcrumb-nav">
            <div className="container">
              <ul className="breadcrumb">
              </ul>
            </div>
          </nav>
          <div className="page-content pt-2">
          {loading ? (

<div className="container-fluied">
  <div className="row">
    <div className="muti-col-loader"></div>
  </div>
</div>

) : (
  <div className="">
  <h2 className="title title-center">Followers</h2>
  <div className="row">
  <div className="tab-content col-lg-12 col-md-12 col-sm-12">
  <div className="d-flex justify-content-end">
<input
type="text"
id="searchInput"
className="form-control col-md-4 col-sm-12"
value={searchQuery}
onChange={(e) => setSearchQuery(e.target.value)}
placeholder="Search by Seller Code"
/>
</div></div></div>
  <div className="tab tab-vertical">
    
    <div className="tab-content col-lg-12 col-md-12">
      <div classNamename="orders">
        <div className="tab-pane" id="orders">
          <table className="order-table">
            <thead>
              <tr>
                <th className="pl-2">Seller Code</th>
                <th>Business Name</th>
                <th>Products</th>
                <th>Category</th>
                <th>Following</th>
                <th>Joining Date</th>
                <th className="pr-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentOrders.map((order) => (
                <tr key={order.sellerid}>
                  <td className="order-number">
                    <a href={"/SellerProduts/"+order.sellerid}>
                      {order.sellercode}</a>
                  </td>
                  <td className="order-date">
                  {order.businessname}
                  </td>
                  <td className="order-status">
                    <span>{order.noofproducts}</span>
                  </td>
                  <td className="order-total">
                    <span>{order.noofcategory}</span>
                  </td>
                  <td className="order-total">
                    <span>{order.followingcount}</span>
                  </td>
                  <td className="order-total">
                    <span>{order.joiningdate}</span>
                  </td>
                  <td className="order-action">
                    <Link to={`/SellerProduts/${order.sellerid}`} className="btn btn-primary btn-link btn-underline">
                      View
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div className="pagination" style={{ textAlign: "end", display: "flex", justifyContent: "flex-end" }}>
<button
className="page-link mr-2"
onClick={() => setCurrentPage(currentPage - 1)}
disabled={currentPage === 1}
>
<FontAwesomeIcon icon={faChevronLeft} />  
</button>

<span className="page-link mx-2">{currentPage}</span>

<button
className="page-link ml-2"
onClick={() => setCurrentPage(currentPage + 1)}
disabled={currentPage === Math.ceil(orders.length / itemsPerPage)}
>
<FontAwesomeIcon icon={faChevronRight} />
</button>
</div>
</div>
)}
          </div>
        </main>
      <Ecomfooter />
    </div>

  )
}

export default Followers;