import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from 'react-router-dom';
const TopMenu = () => {
  const [data, setData] = useState([]);
  const fetchCategoryData = async () => {
    try {
      const payload = {
        currency: localStorage.getItem('currencyName')
      };
      const response = await axios.put("http://68.178.169.226:12080/api/v1/product/getcategoryAndsubcategory", payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 401 || response.status === 404) {
        return;
      }
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchCategoryData();
  }, []);
  return (
    <ul className="menu-items">
      <li>
        <a href="#" className="menu-item"><i class="d-icon-bars2"></i></a>
        <div className="mega-menu">
          <div className="content colum-count">
            {data.map(category => (
              <React.Fragment key={category.categoryid}>
                <ul>
                  <Link to={`/MenuCategory/${category.categoryid}`} className="categoryfont">
                    <h2>{category.categoryname}</h2></Link>
                  {category.subcategory.length > 0 && (
                    category.subcategory.map(subcategory => (
                      <React.Fragment key={subcategory.subcategoryid}>
                        <Link to={`/MenuSub/${subcategory.subcategoryid}`} className="subcategoryfont">
                          <h3>{subcategory.subcategoryname}</h3>
                        </Link>
                        {subcategory.subsubcategory && subcategory.subsubcategory.length > 0 &&
                          subcategory.subsubcategory.map(subsubCategory => (
                            <li key={subsubCategory.subsubcategoryid} className="subsubcategoryfont">
                              <Link to={`/MenuSubSubCategory/${subsubCategory.subsubcategoryid}`} >
                                {subsubCategory.subsubcategoryname}
                              </Link>
                            </li>
                          ))
                        }
                      </React.Fragment>
                    ))
                  )}
                </ul>
              </React.Fragment>
            ))}
          </div>
        </div>
      </li>
    </ul>
  );
};

export default TopMenu;
