
import axios from "axios";
const END_POINT = 'http://68.178.169.226:12080'

const addToWishListAPI = async (productId) => {
    try {
        const response = await fetch(
        `${END_POINT}/api/v1/wishlist/add`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({
                clientid: localStorage.getItem("clientid"),
                productid: productId,
                productquantity: 1,
            }),
        }
        );

        if (response.ok) {
            
            const data = await response.json();
            return data;
        }
    } catch (error) {
        console.error("Error in add WishList: ", error.message);
    }
};


//Fetch wishlist from server and update the global redux state
const getWishListAPI = async () => {
    try {
      const payload = {
        currency: localStorage.getItem('currencyName')
      };
        const response = await axios.post(
        `${END_POINT}/api/v1/wishlist/getbyclient`,payload,
        {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("token")}`,
            }
        }
        );

        if (response.status === 200) {
            
            const data = response.data;
            return data;
        }
    } catch (error) {
        console.error("Error in getWishListAPI: ", error.message);
    }
};

//Deleting Wishlist from server
const deleteFromWishListAPI = async (wishlistid) => {
    try {
      const response = await fetch(
        `${END_POINT}/api/v1/wishlist/delete`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ wishlistid: wishlistid }),
        }
      );
  
      if (response.ok) {

        const data = await response.json();
        return data;
      }
    } catch (error) {
      console.error("Error in deleteFromWishListAPI: ", error.message);
    }
  };
  
//Transfer Local Wishlist to server
async function transferLocalWishListToServer(){
  try {
    const localWishList = JSON.parse(localStorage.getItem('wishlist')) || [];
    let result = [];

    if (localWishList.length > 0) {
      const serverWishList = await getWishListAPI() || [];

      // if (serverWishList) {
        await Promise.all(localWishList.map(async (item) => {
          const isFound = serverWishList.find((wish) => wish.productid === item.productid);

          if (!isFound) {
            let res = await addToWishListAPI(item.productid);

            if (res.status == 'Success') {
              res.productid = item.productid;
            }

            result.push(res);
          }
        }));
      // }
    }

    localStorage.removeItem('wishlist')
    return result;

  } catch (error) {
    console.error("Error in transferLocalCartToServer: ", error.message);
  }
};



// !------------------------------------------------------------------------------------------------------------------------------------
// !----------------------------------------------------------- Cart Block--------------------------------------------------------------
// !------------------------------------------------------------------------------------------------------------------------------------



const addToCartListAPI = async (productId, 
  // productquantity = 1
  quantity1,color) => {
  try {
      const response = await fetch(
      `${END_POINT}/api/v1/cart/add`,
      {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
              clientid: localStorage.getItem("clientid"),
              productid: productId,
              productquantity: 
              // productquantity,
              quantity1,
              color,
          }),
      }
      );

      if (response.ok) {
          
          const data = await response.json();
          return data;
      }
  } catch (error) {
      console.error("Error in add Cart: ", error.message);
  }
};


//Fetch Cart from server and update the global redux state
const getCartListAPI = async () => {
  try {
    const payload = {
      currency: localStorage.getItem('currencyName')
    };
      const response = await axios.post(
      `${END_POINT}/api/v1/cart/getbyclient`,payload,
      {
          headers: {
              "Authorization": `Bearer ${localStorage.getItem("token")}`,
          },
      }
      );

      if (response.status === 200) {
          
          const data = response.data;
          return data;
      }
  } catch (error) {
      console.error("Error in getCartListAPI: ", error.message);
  }
};

//Deleting Cart from server
const deleteFromCartAPI = async (cartid) => {
  try {
    const response = await fetch(
      `${END_POINT}/api/v1/cart/delete`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ cartid: cartid }),
      }
    );

    if (response.ok) {

      const data = await response.json();
      return data;
    }
  } catch (error) {
    console.error("Error in deleteFromCartAPI: ", error.message);
  }
};

//Transfer Local Cart to server
async function transferLocalCartToServer(){
try {
  const localCartList = JSON.parse(localStorage.getItem('cart')) || [];
  let result = [];

  if (localCartList.length > 0) {
    const serverCartList = await getCartListAPI() || [];
    // if (serverCartList) {
      await Promise.all(localCartList.map(async (item) => {
        const isFound = serverCartList.find((cart) => cart.productid === item.productid);

        if (!isFound) {
          let res = await addToCartListAPI(item.productid, item.productquantity || 1);

          if (res.status == 'Success') {
            res.productid = item.productid;
            res.productquantity = item.productquantity || 1;
          }

          result.push(res);
        }
      }));
    // }
    }

  localStorage.removeItem('cart')
  return result;

} catch (error) {
  console.error("Error in transferLocalCartToServer: ", error.message);
}
};


//Edit cart value
async function editCartAPI(cartid, productId, newQuantity){
  try {
    const response = await fetch(
      `${END_POINT}/api/v1/cart/edit`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          cartid: cartid,
          productid: productId,
          productquantity: newQuantity,
        }),
      }
    );

    if (response.ok) {
      return {productId, newQuantity};
    }
  } catch (error) {
    console.error("Error checking authentication:", error);
  }
}

// !------------------------------------------------------------------------------------------------------------------------------------
// !----------------------------------------------------------- Order Block--------------------------------------------------------------
// !------------------------------------------------------------------------------------------------------------------------------------


const getOrdersListAPI = async () => {
  try {
    const response = await axios.post(
      `${END_POINT}/api/v1/order/get`,
      {}, // Empty request body
      {
        headers: {
          "Authorization": `Bearer ${localStorage.getItem("token")}`,
        }
      }
    );

    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    console.error("Error in getOrdersListAPI: ", error.message);
    throw error; // Rethrow the error to handle it in the calling code
  }
};
const newOrdersListAPI = async () => {
  try {
      const response = await fetch(
      `${END_POINT}/api/v1/order/neworder`,
      {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
              clientid: parseInt(localStorage.getItem("clientid")),
          }),
      }
      );

      if (response.ok) {
          
          const data = await response.json();
          return data;
      }
  } catch (error) {
      console.error("Error in add WishList: ", error.message);
  }
};
const cancelOrdersListAPI = async () => {
  try {
      const response = await fetch(
      `${END_POINT}/api/v1/order/getcancelled`,
      {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
              clientid: parseInt(localStorage.getItem("clientid")),
          }),
      }
      );

      if (response.ok) {
          
          const data = await response.json();
          return data;
      }
  } catch (error) {
      console.error("Error in add WishList: ", error.message);
  }
};

// !------------------------------------------------------------------------------------------------------------------------------------
// !----------------------------------------------------------- Followers Block--------------------------------------------------------------
// !------------------------------------------------------------------------------------------------------------------------------------

const followersByCientListAPI = async () => {
  try {
      const response = await fetch(
      `${END_POINT}/api/v1/following/getbyclient`,
      {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
              clientid: parseInt(localStorage.getItem("clientid")),
          }),
      }
      );

      if (response.ok) {
          
          const data = await response.json();
          return data;
      }
  } catch (error) {
      console.error("Error in add WishList: ", error.message);
  }
};

const getCountryListAPI = async () => {
  try {
    const response = await axios.put(
      `${END_POINT}/api/v1/country/get`,
      {}, // Empty request body
      {
        headers: {
          'Content-Type': 'application/json',
        }
      }
    );

    if (response.status === 200) {
      const data = response.data;
      return data;
    }
  } catch (error) {
    console.error("Error in getCountryListAPI: ", error.message);
    throw error; // Rethrow the error to handle it in the calling code
  }
};
const checkPopupForfirstTimeAPI = async () => {
  try {
      const response = await fetch(
      `${END_POINT}/api/v1/order/checkfirsttimebuyer`,
      {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
              clientid: parseInt(localStorage.getItem("clientid")),
          }),
      }
      );

      if (response.ok) {
          
          const data = await response.json();
          return data;
      }
  } catch (error) {
      console.error("Error in add WishList: ", error.message);
  }
};

export {addToWishListAPI, getWishListAPI, deleteFromWishListAPI, transferLocalWishListToServer, getCartListAPI, 
  deleteFromCartAPI, addToCartListAPI, transferLocalCartToServer, editCartAPI, getOrdersListAPI, newOrdersListAPI,cancelOrdersListAPI, followersByCientListAPI, getCountryListAPI,
  checkPopupForfirstTimeAPI
};