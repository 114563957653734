import { createSlice, configureStore, createAsyncThunk } from '@reduxjs/toolkit'
import {addToCartListAPI, addToWishListAPI, deleteFromCartAPI, deleteFromWishListAPI, editCartAPI, transferLocalCartToServer, transferLocalWishListToServer, getCartListAPI} from './Api'
import _ from 'lodash'

function checkLoginStatus(){
    let token = localStorage.getItem('token');
    if(!token){
        window.location.href = '/LoginPage'
    }
}

// ! --------------- Wishlist Block ---------------------!

//Delete Wishlist product
export const deleteProduct = createAsyncThunk('wishlist/deleteProduct', async (wishlistid, thunkAPI) => {
    // checkLoginStatus();
    try {
        //If user not logged in
        let token = localStorage.getItem('token');
        if(!token){
            // window.location.href = '/LoginPage'
            const list = JSON.parse(localStorage.getItem("wishlist")) || []
            let updatedWishList = list.filter(list => list.wishlistid !== wishlistid)
            localStorage.setItem('wishlist', JSON.stringify(updatedWishList))
            thunkAPI.dispatch(updateWishListStateValue(updatedWishList));
            // window.location.reload(false)
        }
        else{
            let result = await deleteFromWishListAPI(wishlistid);
      
            if(result.status == 'Success'){
              result.wishlistid = wishlistid;
              return result;
            }
      
            result.wishlistid = 0;
            return result;
        }


    } catch (error) {
      console.error('Error in deleteProduct(Wishlist): ', error);
    //   throw error;
    }
});


//Add to WishList
export const addProduct = createAsyncThunk('wishlist/addProduct', async (productid, thunkAPI) => {
    // checkLoginStatus();
    
    try {
        //If user not logged in
        let token = localStorage.getItem('token');
        if(!token){
            // window.location.href = '/LoginPage'
            
            let localWishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
            const itemIndex1 = localWishlist.findIndex(
                (wishlistItem) => wishlistItem.productid === productid
            );
            if (itemIndex1 !== -1) {
                const updatedWishList = [...localWishlist];
                updatedWishList[itemIndex1].quantity += 1;
        
                localStorage.setItem("wishlist", JSON.stringify(updatedWishList));
                thunkAPI.dispatch(updateWishListStateValue(updatedWishList));
            } else {
                const updatedWishList = [...localWishlist, { productid, wishlistid: productid  }];
                localStorage.setItem("wishlist", JSON.stringify(updatedWishList));
                thunkAPI.dispatch(updateWishListStateValue(updatedWishList));
            }
        }
        else{
            const currentState = thunkAPI.getState();
            const sameItem = currentState.wishlist.value.filter(
                (wishlistItem) => wishlistItem.productid == productid
            ) || [];
    
            if(sameItem.length < 1){
                let result = await addToWishListAPI(productid);
    
                if(result.status == 'Success'){
                    result.productid = productid;
                    return result;
                }
            }
            return [];
        }



    } catch (error) {
      console.error('Error in deleteProduct(Wishlist): ', error);
    //   throw error;
    }
});

//Transfer Wishlist to server
export const transferWishList = createAsyncThunk('wishlist/transferWishList', async (productid, thunkAPI) => {
    checkLoginStatus();
    try {
        const currentState = thunkAPI.getState();
        const sameItem = currentState.wishlist.value.filter(
            (wishlistItem) => wishlistItem.productid == productid
        ) || [];

        if(sameItem.length < 1){
            let result = await transferLocalWishListToServer();

            if(result.length > 0){
                return result;
            }
        }
        return [];

    } catch (error) {
      console.error('Error in transferWishList: ', error);
    //   throw error;
    }
});


// !-----------------------------------------------------------------------------------------------------------------
// ! ----------------------------------------- Cart Block -----------------------------------------------------------
// !-----------------------------------------------------------------------------------------------------------------
//Delete Cart product
export const deleteCart = createAsyncThunk('cart/deleteCart', async (cartid, thunkAPI) => {
    // checkLoginStatus();
    try {
        //If user not logged in
        let token = localStorage.getItem('token');
        if(!token){
            // window.location.href = '/LoginPage'
            const list = JSON.parse(localStorage.getItem("cart")) || []
            let updatedCartList = list.filter(list => list.cartid !== cartid)
            localStorage.setItem('cart', JSON.stringify(updatedCartList))
            thunkAPI.dispatch(updateCartStateValue(updatedCartList));
            // window.location.reload(false)
        }
        else{
            let result = await deleteFromCartAPI(cartid);
      
            if(result.status == 'Success'){
              result.cartid = cartid;
              return result;
            }
      
            result.wishlistid = 0;
            return result;
        }


    } catch (error) {
      console.error('Error in deleteProduct(Cart): ', error);
    //   throw error;
    }
});


//Add to Cart
export const addCart = createAsyncThunk('cart/addCart', async ({ productid, quantity1 }, thunkAPI) => {
    // checkLoginStatus();
    
    try {
        const currentState = thunkAPI.getState();

        //If user not logged in
        let token = localStorage.getItem('token');
        if(!token){
            // window.location.href = '/LoginPage'
            
            let localCartlist = JSON.parse(localStorage.getItem('cart')) || [];
            const itemIndex1 = localCartlist.findIndex(
                (cart) => cart.productid === productid
            );
            if (itemIndex1 !== -1) {
                const updatedCartList = [...localCartlist];
                updatedCartList[itemIndex1].productquantity += quantity1;
        
                localStorage.setItem("cart", JSON.stringify(updatedCartList));
                thunkAPI.dispatch(updateCartStateValue(updatedCartList));
            } else {
                const updatedCartList = [...localCartlist, { productid, cartid: productid ,productquantity: quantity1 }];
                localStorage.setItem("cart", JSON.stringify(updatedCartList));
                thunkAPI.dispatch(updateCartStateValue(updatedCartList));
            }
        }
        else{
            const sameItem = currentState.cart.value.filter(
                (cart) => cart.productid == productid
            ) || [];
    
            if(sameItem.length < 1){
                let result = await addToCartListAPI(productid,quantity1,'Add to Cart');
    
                if(result.status == 'Success'){
                    result.productid = productid;
                    result.quantity = quantity1;
                    return result;
                }
            }
            return [];
        }

        //removing the wishlist item if it added to cart
        const wishListValue = currentState.wishlist.value
        wishListValue.map(product => {
            const isWishlistFount = wishListValue.find((cartItem) => cartItem.productid == productid)
            if (isWishlistFount) {
                thunkAPI.dispatch(deleteProduct(product.wishlistid));
            }
        })



    } catch (error) {
      console.error('Error in addCart(Cart): ', error);
    //   throw error;
    }
});

//Transfer cart to server
export const transferCart = createAsyncThunk('cart/transferCart', async (productid, thunkAPI) => {
    checkLoginStatus();
    try {
        const currentState = thunkAPI.getState();
        const sameItem = currentState.cart.value.filter(
            (wishlistItem) => wishlistItem.productid == productid
        ) || [];

        if(sameItem.length < 1){
            let result = await transferLocalCartToServer();

            if(result.length > 0){
                return result;
            }
        }
        return [];

    } catch (error) {
      console.error('Error in transferCart: ', error);
    //   throw error;
    }
});


//Edit cart list
export const editCart = createAsyncThunk('cart/editCart', async (productDetails, thunkAPI) => {
    let {productId, cartid, newQuantity} = productDetails
    try {
        let result = {}
        //If user not logged in
        let token = localStorage.getItem('token');

        if(!token){
            
            let localCartlist = JSON.parse(localStorage.getItem('cart')) || [];

            const isFound = localCartlist.findIndex(item => item.productid === productId);

            if (isFound !== -1) {
                if(newQuantity === 0){
                    thunkAPI.dispatch(deleteCart(cartid))
                }
                else{
                    localCartlist[isFound].productquantity =  newQuantity;
                    localStorage.setItem('cart',JSON.stringify(localCartlist))
                    thunkAPI.dispatch(updateCartStateValue(localCartlist));
                }
            }

        }
        else{
            if(newQuantity === 0){
                thunkAPI.dispatch(deleteCart(cartid))
            }
            else{
                result = await editCartAPI(cartid, productId, newQuantity)  
            }
        }

        return result;

    } catch (error) {
      console.error('Error in addCart(Cart): ', error);
    //   throw error;
    }
   
});


const wishListSlice = createSlice({
    name: 'wishlist',
    initialState: {
        value: []
    },
    reducers: {
        updateWishListStateValue: (state, action) => {
            state.value = action.payload || [];
        },
        editProduct: (state, action) => {
            checkLoginStatus()
        }
    },
    extraReducers: (builder) => {
        //Add to wishlist
        builder.addCase(addProduct.fulfilled, (state, action) => {
            if (action.payload && action.payload.status == 'Success') {
                // Add the new item to the state
                state.value = [...state.value, {wishlistid: action.payload.organiserId, productid: action.payload.productid }];
            }
        })

        //Delete from WIshlist
        builder.addCase(deleteProduct.fulfilled, (state, action) => {
            if (action.payload && action.payload.status === 'Success') {
                state.value = state.value.filter(list => list.wishlistid != action.payload.wishlistid);
            }
        })

        //transfer local wishlist to server when login
        builder.addCase(transferWishList.fulfilled, (state, action) => {
            if (action.payload && action.payload.length > 0) {
                state.value = [...state.value, ...action.payload];
            }
        })
    }
})



const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        value: []
    },
    reducers: {
        updateCartStateValue: (state, action) => {
            state.value = action.payload || [];
        }
    },
    extraReducers: (builder) => {
        //Add to cart
        builder.addCase(addCart.fulfilled, (state, action) => {
            if (action.payload && action.payload.status == 'Success') {
                // Add the new item to the state
                console.log("hgjgkj",action.payload.quantity)
                state.value = [...state.value, {cartid: action.payload.organiserId, productid: action.payload.productid, productquantity : action.payload.quantity }];
            }
        })

        //Delete from cart
        builder.addCase(deleteCart.fulfilled, (state, action) => {
            if (action.payload && action.payload.status == 'Success') {
                state.value = state.value.filter(list => list.cartid != action.payload.cartid);
            }
        })

        //transfer local cart to server when login
        builder.addCase(transferCart.fulfilled, (state, action) => {
            if (action.payload && action.payload.length > 0) {
                state.value = [...state.value, ...action.payload];
            }
        })

        //Edit cart quantity
        builder.addCase(editCart.fulfilled, (state, action) => {
            if (action.payload) {
                const isFound = state.value.findIndex(item => item.productid == action.payload.productId);

                if (isFound !== -1) {
                    state.value[isFound].productquantity = action.payload.newQuantity;
                }
            }
        })


    }
})

const orderSlice = createSlice({
    name: 'orderlist',
    initialState: {
        value: []
    },
    reducers: {
        updateOrderStateValue: (state, action) => {
            state.value = action.payload || [];
        },
    },
})
const neworderSlice = createSlice({
    name: 'neworderlist',
    initialState: {
        value: []
    },
    reducers: {
        updateNewOrderStateValue: (state, action) => {
            state.value = action.payload || [];
        },
    },
})
const cancelorderSlice = createSlice({
    name: 'cancelorderlist',
    initialState: {
        value: []
    },
    reducers: {
        updateCancelOrderStateValue: (state, action) => {
            state.value = action.payload || [];
        },
    },
})
const followersByClientSlice = createSlice({
    name: 'followersByClientlist',
    initialState: {
        value: []
    },
    reducers: {
        updatefollowersByClientStateValue: (state, action) => {
            state.value = action.payload || [];
        },
    },
})
const countrySlice = createSlice({
    name: 'countrylist',
    initialState: {
        value: []
    },
    reducers: {
        updatecountryStateValue: (state, action) => {
            state.value = action.payload || [];
        },
    },
})
const checkFirstTimeSlice = createSlice({
    name: 'countrylist',
    initialState: {
        value: []
    },
    reducers: {
        updatecheckFirstTimeStateValue: (state, action) => {
            state.value = action.payload || [];
        },
    },
})
export const {  editProduct, updateWishListStateValue } = wishListSlice.actions
export const {  updateCartStateValue } = cartSlice.actions
export const { updateOrderStateValue } = orderSlice.actions;
export const { updateNewOrderStateValue } = neworderSlice.actions;
export const { updateCancelOrderStateValue } = cancelorderSlice.actions;
export const { updatefollowersByClientStateValue } = followersByClientSlice.actions;
export const { updatecountryStateValue } = countrySlice.actions;
export const { updatecheckFirstTimeStateValue } = checkFirstTimeSlice.actions;

export default configureStore({
    reducer: {
        wishlist: wishListSlice.reducer,
        cart: cartSlice.reducer,
        order: orderSlice.reducer,
        neworder: neworderSlice.reducer,
        cancelorder: cancelorderSlice.reducer,
        followersByClient: followersByClientSlice.reducer,
        country: countrySlice.reducer,
        checkFirstTime: checkFirstTimeSlice.reducer,
        // Add other reducers as needed
      }
})

