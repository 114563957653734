import React,{useState, useEffect} from "react";
import axios from "axios";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import Ecomheader from "../../src/header/headershop";
import Ecomfooter from "../footer/footershop";
import { Helmet } from "react-helmet";
const Order =()=> {
  const navigate = useNavigate();
    const [orderData, setOrderData] = useState(null);
    const [cartEmpty, setCartEmpty] = useState(true);
    useEffect(() => {
		fetchData();
	}, []);
      const fetchData = async () => {
		try {
			const payload = {
				orderid: parseInt(localStorage.getItem('organiserId')) 
			  };
		  const response = await axios.post("http://68.178.169.226:12080/api/v1/order/getbyid",payload,{
			
		  headers: {
			'Authorization': `Bearer ${localStorage.getItem('token')}`,
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			// navigate('/login');
			return; // Exit early to prevent further logic execution
		  }
		  setOrderData(response.data);
       // Check if the cart is empty
       if (response.data && response.data.products.length === 0) {
        setCartEmpty(true);
      } else {
        setCartEmpty(false);
      }
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };
    function formatDate(dateTimeString) {
      const date = new Date(dateTimeString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
      return (
        <div>
        <div>
            <Helmet>
                <title>Order</title>
            </Helmet>
        </div>
            <Ecomheader />
        <main className="main order">
          <div className="page-content pt-7 pb-10 mb-10">
            <div className="step-by pr-4 pl-4">
              <h3 className="title title-simple title-step">
                <Link to="/Cart">1. Cart</Link>
              </h3>
              <h3 className="title title-simple title-step">
                <Link to="/Checkout">2. CheckOut</Link>
              </h3>
              <h3 className="title title-simple title-step active">
                <Link to="/Order">3. Complete</Link>
              </h3>
            </div>
    
            <div className="container mt-8">
              <div className="order-message mr-auto ml-auto">
              {cartEmpty ? (
                <p>Your cart is empty.</p>
              ) : (
                <div className="icon-box d-inline-flex align-items-center">
                  <div className="icon-box-icon mb-0"></div>
    
                  <div className="icon-box-content text-left">
                    <h5 className="icon-box-title font-weight-bold lh-1 mb-1">
                      Thank You!
                    </h5>
                    <p className="lh-1 ls-m">Your order has been received Click here to view your <span onClick={() => navigate("/ViewOrder/"+localStorage.getItem("organiserId"))}>Order Summary</span></p>
                    
                  </div>
                </div>
              )}
              </div>
    
            </div>
          </div>
        </main>
<Ecomfooter />
        </div>
      );
}
export default Order;