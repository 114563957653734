import React, { useState, useEffect, useRef } from 'react';
import noimage from '../../src/images/noimage.jpg';
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Input, Form, Upload, Button, Modal, notification } from 'antd';


const SellerProfileDetails = ({ id }) => {
    const [sellerDetailsforclient, setSellerDetailsforclient] = useState('');
    const clientId = localStorage.getItem('clientid');

    const navigate = useNavigate();
    const location = useLocation();
    const [showAlert, setShowAlert] = useState(false);
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {

            // Fetch seller details for the client
            const sellerDetailsResponse = await axios.put(
                "http://68.178.169.226:12080/api/v1/seller/sellerdetails/getforclient",

                {
                    clientid: clientId,
                    sellerid: id
                }
            );

            // Update state with seller details for the client
            if (sellerDetailsResponse.status === 200) {
                setSellerDetailsforclient(sellerDetailsResponse.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }
    const renderSocialMediaIcons = () => {
        if (!sellerDetailsforclient.sellerSocialmedia) {
            return null; // If sellerSocialmedia is undefined, return null
        }

        return sellerDetailsforclient.sellerSocialmedia.map(socialMedia => (
            <a key={socialMedia.socialmediaid} href={socialMedia.description} target="_blank" rel="noopener noreferrer">
                <i className={`r-icon ${socialMedia.iconname}`}></i>
            </a>
        ));
    };

    const navigateToSellerProducts = () => {
        // Navigate to the SellerProducts page with the sellerid as a parameter
        navigate(`/SellerProduts/${id}`);
    };
    const closeAlert = () => {
        setShowAlert(false);
    };
    const handleFollow = async () => {
        if (!id) {
            setShowAlert(true);
        } else {
            try {
                const followPayload = {
                    clientid: parseInt(clientId),
                    sellerid: id
                };

                const followResponse = await axios.post(
                    "http://68.178.169.226:12080/api/v1/following/add",
                    followPayload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );

                // Handle successful follow
                fetchData();
                notification.success({
                    message: 'You are now following this seller.',
                    duration: 2,
                });
            } catch (error) {
                console.error("Error following:", error);
            }
        }
    };
    const handleUnfollow = async () => {
        if (!id) {
            setShowAlert(true);
        } else {
            try {
                const followPayload = {
                    clientid: parseInt(clientId),
                    sellerid: id
                };

                const followResponse = await axios.post(
                    "http://68.178.169.226:12080/api/v1/following/delete",
                    followPayload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );

                // Handle successful follow
                fetchData();
                notification.success({
                    message: 'You have unfollowed this seller.',
                    duration: 2,
                });
            } catch (error) {
                console.error("Error following:", error);
            }
        }
    };
    // return (
    //     <div className="container-fluid seller-store">
    //         <div className='row'>
    //             <div className='col-lg-2 col-md-2 col-sm-2 p-0 d-flex justify-content-center'>
    //                 <div className="seller-img" onClick={() => location.pathname.startsWith("/Product") && navigateToSellerProducts()}><img src={sellerDetailsforclient.profilepic ? sellerDetailsforclient.profilepic : noimage} alt={sellerDetailsforclient.sellerid} /></div>
    //             </div>
    //             <div className='col-lg-4 col-md-4 col-sm-10'>
    //                 <div className="seller-info">
    //                     <div className='seller-info-set'>
    //                         <p> <span className='seller-name' onClick={() => location.pathname.startsWith("/Product") && navigateToSellerProducts()}>{sellerDetailsforclient.businessname}</span></p>
    //                         <p>({sellerDetailsforclient.lastseen}) </p>
    //                         {renderSocialMediaIcons()}
    //                     </div>

    //                 </div>
    //             </div>
    //             <div className='col-lg-4 col-md-4 col-sm-12'>
    //                 <div className='seller-info-set row'>
    //                     <p className='col-lg-6 col-md-6 col-sm-6'>Products : {sellerDetailsforclient.noofproducts}  </p><p className='col-lg-6 col-md-6 col-sm-6'>Category  :   {sellerDetailsforclient.noofcategory}  </p>
    //                 </div>
    //                 <div className='seller-info-set row'>
    //                     <p className='col-lg-6 col-md-6 col-sm-6'>Since : {sellerDetailsforclient.joiningdate} </p>

    //                     <p className='follower col-lg-6 col-md-6 col-sm-6'>Follower : {sellerDetailsforclient.followingcount}</p><p></p>
    //                 </div>
    //             </div>
    //             <div className="col-md-2 col-sm-12 seller-view-page-bg">
    //                 <span className="seller-view-page" onClick={location.pathname.startsWith("/Product") ? navigateToSellerProducts : (sellerDetailsforclient.following ? handleUnfollow : handleFollow)}>
    //                     {location.pathname.startsWith("/Product") ? "View Shop" : (sellerDetailsforclient.following ? "Unfollow" : "Follow")}
    //                 </span>
    //             </div>
    //         </div>

    //         <Modal
    //             visible={showAlert}
    //             onCancel={closeAlert}
    //             footer={[
    //                 <Button key="login" type="btn btn-dark btn-block btn-rounded" onClick={() => {
    //                     closeAlert();
    //                     window.location.href = '/LoginPage';
    //                 }}>
    //                     Login
    //                 </Button>,
    //             ]}
    //         >
    //             <p>Please Login to Proceed.</p>
    //         </Modal>
    //     </div>
    // );
};

export default SellerProfileDetails;
