import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios"; // Import Axios for making API requests
import banner from "../../src/images/elements/floating/1.png";
import banner1 from "../../src/images/elements/floating/1.png";
import Carousel from "react-bootstrap/Carousel";
import './demo.css'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import useWindowDimensions from "../Hooks/useWindowDimensions";


const Megashopourcato = ({ categories = [] ,showViewAll = false}) => {



  const { height, width } = useWindowDimensions();
  const mobileHight = width < 600 ? '100px' : '190px'
  // const [categories, setCategories] = useState([]);

  // useEffect(() => {
  //   // Fetch data from the API
  //   axios
  //     .put("http://68.178.169.226:12080/api/v1/category/get")
  //     .then((response) => {
  //       // Assuming the API response is an array of category objects
  //       setCategories(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, []);

  var settings = {
    dots: true,
    infinite: false,
    speed: 400,
    slidesToShow:  4,
    // slidesToShow: width < 600 ? 4 : 6,
    slidesToScroll: 2,
    className: 'slides',
    rows: categories.length > 4 ? 2 : 1,
    // rows: 2,/
};


  return (
    <section className="container">
      {/* <div className="container homebg">
        <div className="row">
          <div className="col-xs-10"> */}
            {/* <h2 className="title title-center mb-5">Our Categories</h2> */}
          {/* </div> */}
           
          


          

          <div className="flex" style={{justifyContent: 'space-between' }}>
            <h2 class="section-title">Category</h2>
            {showViewAll && <a href="AllCategory" class="section-title cursor-pointer hover:text-blue-600">View All</a>}
          </div>
          <div className="category">
          {categories.map((category, index) => (
            
               <div className="">
                <Link to={`/MenuCategory/${category.categoryid}`} class="round-shape" >
                  <img src={category.fileModel?.file} alt="intro-banner"  />
                </Link>

                <Link to={`/MenuCategory/${category.categoryid}`} className={`category-name`}>
                  {category.categoryname}
                </Link>

              </div>
            
 
            // </div>
          ))}
          </div>
           



          {/* </Carousel> */}
        {/* </div>
      </div> */}
    </section>
  );
};
export default Megashopourcato;
