import React, { useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import '../header/headshop.css';


const promise = loadStripe("pk_live_51OChFxI0wQSu3P50fqcaBLffjOwFTqVdBIus13MxZgOredCuCXatXVsugcMUfO9kh4wqYMCNiPSm4cqhfpPm7BD800jc8ESOGf");
// Initialize Stripe Elements
export default function Stripe({orderData,clientSecret, isSucceeded}) {

  return (

    <>
    <div style={{display: 'flex', justifyContent: 'center',width:"100%", height: '100vh'}}>
    <div style={{width:"30%",  alignSelf: 'center'}}>
      {clientSecret && promise && (
        <Elements stripe={promise} options={{ clientSecret }}>
          <CheckoutForm orderData={orderData} isSucceeded ={isSucceeded} />
        </Elements>
      )}</div></div>
    </>
  );
}