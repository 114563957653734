import React, { useState, useEffect } from 'react';
import "./../src/navbarHead/navmenu.css";
import "./../src/header/fonticon.css";
import './App.css';
const SideBar = ({ categories }) => {
    return (
        <>
            {Array.isArray(categories) && categories.length > 0 && (
                <ul className="sidebar-menu">
                    {categories.map((category) => (
                        <li key={category.categoryid} className="sidebar-item">
                            <a href="menuCategory/:categoryid" className="category-link">{category.categoryname}</a>
                            {Array.isArray(category.subcategory) && category.subcategory.length > 0 && (
                                <ul className="subcategory-menu">
                                    {category.subcategory.map((subcategory) => (
                                        <li key={subcategory.subcategoryname} className="subcategory-item">
                                            <a href="MenuSub/:subcategoryid" className="subcategory-link">{subcategory.subcategoryname}</a>
                                            {Array.isArray(subcategory.subsubcategory) && subcategory.subsubcategory.length > 0 && (
                                                <ul className="subsubcategory-menu">
                                                    {subcategory.subsubcategory.map((subsubcategory) => (
                                                        <li key={subsubcategory.subsubcategoryname} className="subsubcategory-item">
                                                            <a href="menuCategory/:categoryid" className="subsubcategory-link">{subsubcategory.subsubcategoryname}</a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
}
export default SideBar;